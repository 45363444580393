<template>
  <div>
    <el-dialog
      destroy-on-close
      width="584px"
      title="发布IP需求"
      v-model="dialogFormVisible"
    >
      <template #header="{ titleId }">
        <div v-if="this.applyStatus === 0" class="my-header">
          <h3 :id="titleId" class="demand-card-title">发布IP需求</h3>
        </div>
        <div
          v-if="this.applyStatus === 1"
          class="demand-card-return"
          @click="this.applyStatus = 0"
        >
          <img src="../../assets/images/index/return_arrow.png" /><span
            >返回修改IP需求</span
          >
        </div>
      </template>
      <div class="demand-wrap">
        <div v-show="this.applyStatus === 0" class="demand-card demand">
          <el-form ref="demandForm" :model="params" :rules="rules">
            <el-form-item
              v-for="(item, index) in optionList"
              :key="index"
              :label="item.title"
              :label-width="120"
              :prop="item.key"
            >
              <el-select
                v-model="this.params[item.key]"
                :placeholder="item.placeholder"
                class="demand-option"
                size="large"
              >
                <el-option
                  v-for="subitem in item.options"
                  :key="subitem.value"
                  :label="subitem.label"
                  :value="subitem.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="合作大致时间" :label-width="120" prop="date">
              <el-date-picker
                v-model="this.params.date"
                type="month"
                placeholder="请选择合作大致时间"
                style="width: 360px"
                size="large"
              >
              </el-date-picker>
            </el-form-item>
          </el-form>
          <div class="demand-card-content">
            <el-button
              type="primary"
              class="demand-apply-btn"
              @click="submitForm"
              >发布IP合作需求</el-button
            >
            <p class="footer-text">发布以后平台客服人员将会与您联系</p>
          </div>
        </div>
        <div v-show="this.applyStatus === 1" class="demand-card demand">
          <div class="demand-card-content">
            <div class="demand-detail-text">
              请确认您的IP需求：当前您的项目处在
              <span class="highlight">{{
                this.formatLabel(this.params.stage, 0)
              }}</span>
              阶段，大致的IP预算范围为
              <span class="highlight">{{
                this.formatLabel(this.params.budget, 1)
              }}</span>
              ，您希望找
              <span class="highlight">{{
                this.formatLabel(this.params.ipType, 2)
              }}</span>
              类型的IP，合作大致时间为
              <span class="highlight">{{
                this.formatDate(this.params.date)
              }}</span>
            </div>
            <p class="phone-text">请输入您的手机号，用于平台客服人员联系您</p>
            <div class="phone-form">
              <el-input
                v-model="this.phone"
                class="phone-input"
                placeholder="请输入手机号"
              />
              <div class="verifycode">
                <el-input
                  v-model="this.verifyCode"
                  class="verifyCode-input"
                  placeholder="请输入验证码"
                />
                <div class="verify-text">
                  <span
                    class="verify-get"
                    @click="getVerifyCode"
                    v-show="this.verifyTime === 0"
                  >
                    获取验证码
                  </span>
                  <span class="verify-wait" v-show="this.verifyTime > 0">
                    重新发送({{ this.verifyTime }})
                  </span>
                </div>
              </div>
            </div>
            <el-button
              type="primary"
              class="demand-apply-btn"
              @click="handleApplyForm"
              >确定发布</el-button
            >

            <p class="footer-text">发布以后平台客服人员将会与您联系</p>
          </div>
        </div>
      </div>
    </el-dialog>
    <el-button
      type="primary"
      class="modal-btn"
      @click="dialogFormVisible = true"
      :style="{ width: btnWidth, height: btnHeight }"
      >发布IP合作需求</el-button
    >
  </div>
</template>

<script>
import useStore from "@/store/index.js";
import dayjs from "dayjs";
import icom from "@/common/com.js";

// 示例: <demandApplyModal btnWidth="160px" btnHeight="40px"></demandApplyModal>
export default {
  props: {
    btnWidth: {
      type: String,
      default: "128px",
      required: false,
    },
    btnHeight: {
      type: String,
      default: "32px",
      required: false,
    },
  },
  data() {
    return {
      dialogFormVisible: false,
      applyStatus: 0,
      demandList: [
        {
          title: "资源碎片化",
          content: "5000+直联IP资源一键获取",
        },
        {
          title: "价格不透明",
          content: "全部为IP一手价格，拒绝加价倒卖",
        },
        {
          title: "创意执行难",
          content: "平台400+创意热店提供创意支持",
        },
        {
          title: "合作太复杂",
          content: "专业IP服务团队提供一站式服务",
        },
      ],
      formVisible: true,
      ipTypeOption: [],
      params: {
        stage: "",
        budget: "",
        ipType: "",
        date: "",
      },
      optionList: [
        {
          placeholder: "请选择项目阶段",
          title: "项目阶段",
          key: "stage",
          value: this.params ? this.params.stage : "",
          options: [
            {
              value: "1",
              label: "筹备",
            },
            {
              value: "2",
              label: "比稿",
            },
            {
              value: "3",
              label: "执行",
            },
          ],
        },
        {
          placeholder: "请选择大致的IP预算范围",
          title: "大致IP预算范围",
          key: "budget",
          options: [
            {
              value: "1",
              label: "5万以下",
            },
            {
              value: "2",
              label: "5万-30万",
            },
            {
              value: "3",
              label: "30万-60万",
            },
            {
              value: "4",
              label: "60万-100万",
            },
            {
              value: "5",
              label: "100万以上",
            },
          ],
        },
        {
          placeholder: "请选择意向合作IP类型",
          title: "意向合作IP类型",
          key: "ipType",
          options: [],
        },
      ],
      verifyTime: 0,
      verifyCode: "",
      phone: "",
      rules: {
        stage: [
          {
            required: true,
            message: "请选择项目阶段",
            trigger: "change",
          },
        ],
        budget: [
          {
            required: true,
            message: "请选择大致的IP预算范围",
            trigger: "change",
          },
        ],
        ipType: [
          {
            required: true,
            message: "请选择意向合作IP类型",
            trigger: "change",
          },
        ],
        date: [
          {
            required: true,
            message: "请选择合作大致时间",
            trigger: "change",
          },
        ],
      },
    };
  },
  methods: {
    showLogin() {
      this.store.setCurComp("login");
    },
    showRegister() {
      this.store.setCurComp("register");
    },
    hideCopm() {
      this.store.setCurComp("");
    },
    handleSelectChange(key, value) {
      const params = Object.assign({}, this.params, {
        [key]: value,
      });
      this.params = params;
    },
    getIpType() {
      this.API.ip_type_dict({}, (res) => {
        const { data } = res;
        this.optionList[2].options = data;
      });
    },
    formatDate(date) {
      return dayjs(date).format("YYYY年MM月");
    },
    formatLabel(value, index) {
      const ipItem = this.optionList[index].options.find(
        (item) => item.value === value,
        index
      );
      return ipItem ? ipItem.label : "";
    },
    getVerifyCode() {
      if (this.phone == "") {
        this.$message.error("请输入手机号");
        return false;
      }
      if (!icom.checkStr(this.phone)) {
        this.$message.error("请输入正确的手机号");
        return false;
      }
      const self = this;
      this.API.send_msg_code({ phone: this.phone, source: 5 }, (res) => {
        self.verifyTime = 60;
        const timer = setInterval(() => {
          if (self.verifyTime > 0) {
            self.verifyTime--;
          } else {
            clearInterval(timer);
          }
        }, 1000);
      });
    },
    handleApplyForm() {
      if (this.phone == "") {
        this.$message.error("请输入手机号");
        return false;
      }
      if (!icom.checkStr(this.phone)) {
        this.$message.error("请输入正确的手机号");
        return false;
      }
      if (this.verifyCode == "") {
        this.$message.error("请输入验证码");
        return false;
      }
      // TOFO 提交手机号，需求信息
      this.API.apply_demand(
        {
          projectPhase: this.params.stage,
          intentIpType: this.params.ipType,
          budgetRange: this.params.budget,
          cooperationMonth: dayjs(this.params.date).format("YYYY-MM"),
          phone: this.phone,
          code: this.verifyCode,
        },
        (res) => {
          this.$message({
            message: "您的需求已成功提交，稍后我们客服人员会与您联系",
            type: "success",
            duration: 2000,
          });
          this.dialogFormVisible = false;
          this.reset();
        }
      );
    },
    submitForm() {
      const formEl = this.$refs.demandForm;
      if (!formEl) return;
      formEl.validate((valid) => {
        if (valid) {
          if (this.logins.loginStatus) {
            this.phone = this.logins.loginInfo.fullPhone;
          }
          this.applyStatus = 1;
        } else {
        }
      });
    },
    reset() {
      this.params = {
        stage: "",
        budget: "",
        ipType: "",
        date: "",
      };
      this.applyStatus = 0;
      this.phone = "";
      this.verifyCode = "";
    },
  },
  created() {
    this.store = useStore();
    this.logins = this.store.logins;
    this.getIpType();
  },
  watch: {
    dialogFormVisible(curVal, oldVal) {
      if (!curVal) {
        this.reset();
      }
    },
  },
};
</script>

<style lang="less" scoped>
.dialog-box {
  width: 584px;
  height: 474px;
  background: #ffffff;
  border-radius: 8px;
}

.modal-btn {
  width: 128px;
  height: 32px;
  background: #f5630b;
  border-radius: 4px;
}

.demand-card-title {
  font-size: 18px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: rgba(4, 4, 4, 0.8);
  line-height: 26px;
}
.demand-card-return {
  display: flex;
  align-items: center;
  height: 26px;
  img,
  span {
    cursor: pointer;
  }
  span {
    font-size: 18px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: rgba(4, 4, 4, 0.8);
    line-height: 26px;
  }
  img {
    width: 24px;
    height: 24px;
  }
}

.demand-wrap {
  margin: 0 auto;
  background: #ffffff;
  .demand-detail-text {
    font-size: 16px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #222222;
    line-height: 24px;
  }

  .demand-card {
    width: 100%;
  }
  .demand {
    margin-right: 128px;
  }

  .demand-card-content {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 60px;
  }

  .demand-option {
    width: 360px;
    height: 44px;
    background: #ffffff;
  }

  .demand-apply-btn {
    margin-top: 30px;
    width: 424px;
    height: 44px;
  }
  .phone-text {
    font-size: 14px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #606060;
    line-height: 22px;
    margin-top: 24px;
    text-align: left;
    width: 100%;
  }
  .phone-form {
    width: 100%;
    .phone-input {
      width: 100%;
      height: 44px;
      margin-right: 16px;
      margin-top: 16px;
      :deep(.el-input__inner) {
        padding-left: 13px;
      }
    }
    .verifyCode-input {
      height: 44px;
      :deep(.el-input__inner) {
        padding-right: 92px;
        padding-left: 13px;
      }
    }

    .verifycode {
      position: relative;
      width: 100%;
      user-select: none;
      margin-top: 16px;
      .verify-text {
        position: absolute;
        right: 24px;
        top: 7px;
        font-size: 14px;
        line-height: 30px;
        .verify-get {
          cursor: pointer;
          color: #ea5504;
        }
        .verify-wait {
          pointer-events: none;
          color: #888888;
        }
      }
    }
  }

  .footer-text {
    text-align: center;
    width: 100%;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #888888;
    line-height: 22px;
    margin-top: 16px;
  }

  .highlight {
    font-size: 16px;
    color: #ea5504;
  }
}
</style>
