import useStore from "@/store/index.js";
import { API } from "@/api/index.js";
var utils = {
  async get_info(API, store, cb) {
    const result = await  API.get_info({});
    const res = result.data
    if (res.code != 0) {
      if (cb) cb(res);
      return;
    }
    store.Login();
    var data = {};
    data.code = 0;
    data.email = res.data.email;
    data.fullPhone = res.data.phone;
    data.phone = res.data.phone.replace(
      res.data.phone.substring(3, 7),
      "****"
    );
    data.company = res.data.basic_info.company;
    data.refuse_reason = res.data.basic_info.refuse_reason;
    data.status = res.data.basic_info.status;
    // data.refuse_reason = 'ddd'
    // data.status = 1
    store.setloginInfo({...res.data,...data});
    // status '' 未填写  1待审核 2已通过 3 已驳回
    store.setCheck(data.status);
    store.setType(res.data.type);
    if (cb) cb(data);
  },

  async goto(_this, url, query, store) {
    this.store = useStore();
    this.logins = this.store.logins;
    // console.log(this.logins)
    if(!this.logins.loginStatus){
      this.store.setCurComp('login');
      return
    }
    // const { iid } = query;
    // if (iid) {
		// 	const res  = await API.check_ip_right({ ipid: iid });
		// 	const {code} = res.data;
		// 	if(code === 15){
		// 		if(this.logins.checkStatus!=2){
		// 			//未认证弹出认证弹窗
		// 			this.store.setCurComp('authentication');
		// 		}else{
		// 			//已认证弹出数量限制弹窗
		// 			this.store.setCurComp("ipQuotaLimit");
		// 		}
		// 	}else if(code === 5){
    //     this.store.setCurComp("guide");
    //   }
		// 	if(code !== 0)return 
    // }  
		const routeData = _this.$router.resolve({
			path: url,
			query: query,
		});
    // console.log(routeData)
		window.open(routeData.href, "_blank");
  },
};
export default utils;
