<template>

	
	<div class="forget authentication">
			<div class="regbox ">
				<div class="regcont">
					<div class="tit"> <a v-show="loginCloseShow" @click="hideCopm" class="close"><img src="../assets/images/register/close.png" alt="" ></a></div>
					<div class="form">
						<p>当前帐号信息未认证</p>
						<p>{{logins.checkText}}</p>
						<a  class="btn1" @click="toAuth">立即前往认证</a>
					</div>
				</div>
			</div>
		</div>
	
	
	
</template>

<script>
	import useStore from '@/store/index.js'
	
	export default {
		data(){
			return {
			}
		},
		created(){
			this.store = useStore();
			this.logins = this.store.logins;
		},
		computed:{
			loginCloseShow(){
				return this.store.logins.loginCloseShow;
			}
		},
		methods:{
			hideCopm(){
				this.store.setCurComp('');
			},
			toAuth(){
				this.store.setCurComp('');
				this.$router.push('/person/baseinfo');
			},
			
			
			
		},
		
		
	}
</script>

<style scoped>
.authentication .regcont{width: 410px;height: 240px;text-align: center;}
.authentication .regcont .form {width: 410px}
.authentication .regcont .form p{color: #2E3036;font-size: 14px;margin-top: 15px;;}
.authentication .regcont .btn1{width: 164px;margin-top: 32px;line-height: 42px;height: 42px;;}	
</style>
