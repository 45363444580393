<template>
  <div class="demand-wrap" v-if="this.visible">
    <div v-show="this.applyStatus === 0" class="demand-card demand">
      <h3 class="demand-card-title">发布IP需求</h3>
      <div class="demand-card-content">
        <div class="demand-card-selects">
          <el-select
            v-for="(item, index) in optionList"
            :key="index"
            @change="(value) => handleSelectChange(item.key, value)"
            v-model="this.params[item.key]"
            :placeholder="item.placeholder"
            class="demand-option"
            size="large"
          >
            <el-option
              v-for="subitem in item.options"
              :key="subitem.value"
              :label="subitem.label"
              :value="subitem.value"
            >
            </el-option>
          </el-select>
          <el-date-picker
            v-model="this.params.date"
            type="month"
            placeholder="请选择合作大致时间"
            style="width: 100%"
            size="large"
          >
          </el-date-picker>
        </div>
        <el-button
          type="primary"
          class="demand-apply-btn"
          @click="handleApply(1)"
          >发布</el-button
        >
        <p class="footer-text">发布以后平台客服人员将会与您联系</p>
      </div>
    </div>
    <div v-show="this.applyStatus === 1" class="demand-card demand">
      <div class="demand-card-return" @click="handleApply(0)">
        <img src="../../assets/images/index/return_arrow.png" /><span
          >返回修改IP需求</span
        >
      </div>
      <div class="demand-card-content">
        <div class="demand-detail-text">
          请确认您的IP需求：当前您的项目处在
          <span class="highlight">{{
            this.formatLabel(this.params.stage, 0)
          }}</span>
          阶段，大致的IP预算范围为
          <span class="highlight">{{
            this.formatLabel(this.params.budget, 1)
          }}</span>
          ，您希望找
          <span class="highlight">{{
            this.formatLabel(this.params.ipType, 2)
          }}</span>
          类型的IP，合作大致时间为
          <span class="highlight">{{ this.formatDate(this.params.date) }}</span>
        </div>
        <p class="phone-text">请输入您的手机号，用于平台客服人员联系您</p>
        <div class="phone-form">
          <el-input
            v-model="this.phone"
            class="phone-input"
            placeholder="请输入手机号"
          />
          <div class="verifycode">
            <el-input
              v-model="this.verifyCode"
              class="verifyCode-input"
              placeholder="请输入验证码"
            />
            <div class="verify-text">
              <span
                class="verify-get"
                @click="getVerifyCode"
                v-show="this.verifyTime === 0"
              >
                获取验证码
              </span>
              <span class="verify-wait" v-show="this.verifyTime > 0">
                重新发送({{ this.verifyTime }})
              </span>
            </div>
          </div>
        </div>
        <el-button
          type="primary"
          class="demand-apply-btn"
          style="margin-top: 16px"
          @click="handleApplyForm"
          >确定发布</el-button
        >
        <p class="footer-text">发布以后平台客服人员将会与您联系</p>
      </div>
    </div>
    <div class="demand-card">
      <h3 class="demand-card-title">IP宇宙可以帮您解决</h3>
      <div class="demand-card-content">
        <div class="demand-info-list">
          <div
            v-for="(item, index) in demandList"
            :key="index"
            class="demand-info-item"
          >
            <img
              class="info-img"
              src="../../assets/images/index/squarecheck.png"
            />
            <strong class="info-title">{{ item.title }}</strong>
            <p class="info-content">{{ item.content }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import useStore from "@/store/index.js";
import dayjs from "dayjs";
import icom from "@/common/com.js";
export default {
  data() {
    return {
      visible: true,
      applyStatus: 0,
      demandList: [
        {
          title: "资源碎片化",
          content: "5000+直联IP资源一键获取",
        },
        {
          title: "价格不透明",
          content: "全部为IP一手价格，拒绝加价倒卖",
        },
        {
          title: "创意执行难",
          content: "平台400+创意热店提供创意支持",
        },
        {
          title: "合作太复杂",
          content: "专业IP服务团队提供一站式服务",
        },
      ],
      ipTypeOption: [],
      params: {
        stage: "",
        budget: "",
        ipType: "",
        date: "",
      },
      optionList: [
        {
          placeholder: "请选择项目阶段",
          title: "项目阶段",
          key: "stage",
          value: this.params ? this.params.stage : "",
          options: [
            {
              value: "1",
              label: "筹备",
            },
            {
              value: "2",
              label: "比稿",
            },
            {
              value: "3",
              label: "执行",
            },
          ],
        },
        {
          placeholder: "请选择大致的IP预算范围",
          title: "大致IP预算范围",
          key: "budget",
          options: [
            {
              value: "1",
              label: "5万以下",
            },
            {
              value: "2",
              label: "5万-30万",
            },
            {
              value: "3",
              label: "30万-60万",
            },
            {
              value: "4",
              label: "60万-100万",
            },
            {
              value: "5",
              label: "100万以上",
            },
          ],
        },
        {
          placeholder: "请选择意向合作IP类型",
          title: "意向合作IP类型",
          key: "ipType",
          options: [],
        },
      ],
      verifyTime: 0,
      verifyCode: "",
      phone: "",
    };
  },
  methods: {
    showLogin() {
      this.store.setCurComp("login");
    },
    showRegister() {
      this.store.setCurComp("register");
    },
    hideCopm() {
      this.store.setCurComp("");
    },
    handleSelectChange(key, value) {
      const params = Object.assign({}, this.params, {
        [key]: value,
      });
      this.params = params;
    },
    getIpType() {
      this.API.ip_type_dict({}, (res) => {
        const { data } = res;
        this.optionList[2].options = data;
      });
    },
    handleApply(type) {
      const self = this;
      let flag = true;
      for (let item of this.optionList) {
        if (!self.params[item.key]) {
          self.store.showToast(item.placeholder);
          flag = false;
          break;
        }
      }
      if (!flag) {
        return;
      }
      if (!self.params.date) {
        self.store.showToast(`请选择合作大致时间`);
        return;
      }
      if (this.logins.loginStatus) {
        this.phone = this.logins.loginInfo.fullPhone;
      }
      this.applyStatus = type;
    },
    formatDate(date) {
      return dayjs(date).format("YYYY年MM月");
    },
    formatLabel(value, index) {
      const ipItem = this.optionList[index].options.find(
        (item) => item.value === value,
        index
      );
      return ipItem ? ipItem.label : "";
    },
    getVerifyCode() {
      if (this.phone == "") {
        this.store.showToast("请输入手机号");
        return false;
      }
      if (!icom.checkStr(this.phone)) {
        this.store.showToast("请输入正确的手机号");
        return false;
      }
      const self = this;
      this.API.send_msg_code({ phone: this.phone, source: 5 }, (res) => {
        self.verifyTime = 60;
        const timer = setInterval(() => {
          if (self.verifyTime > 0) {
            self.verifyTime--;
          } else {
            clearInterval(timer);
          }
        }, 1000);
      });
    },
    handleApplyForm() {
      if (this.phone == "") {
        this.store.showToast("请输入手机号");
        return false;
      }
      if (!icom.checkStr(this.phone)) {
        this.store.showToast("请输入正确的手机号");
        return false;
      }
      if (this.verifyCode == "") {
        this.store.showToast("请输入验证码");
        return false;
      }
      // TODO 提交手机号，需求信息
      this.API.apply_demand(
        {
          projectPhase: this.params.stage,
          intentIpType: this.params.ipType,
          budgetRange: this.params.budget,
          cooperationMonth: dayjs(this.params.date).format("YYYY-MM"),
          phone: this.phone,
          code: this.verifyCode,
        },
        (res) => {
          this.$message({
            message: "您的需求已成功提交，稍后我们客服人员会与您联系",
            type: "success",
            duration: 2000,
          });
          this.$emit("applyDemandCallback");
          this.visible = false;
        }
      );
    },
  },
  created() {
    this.store = useStore();
    this.logins = this.store.logins;
    this.getIpType();
  },
};
</script>

<style lang="less" scoped>
.demand-wrap {
  max-height: 390px;
  width: 80%;
  min-width: 1200px;
  margin: 0 auto;
  background: #ffffff;
  box-shadow: 0px 4px 6px 0px rgba(117, 125, 135, 0.12);
  border-radius: 4px;
  display: flex;
  justify-content: space-between;

  padding: 0 48px 32px 48px;

  transform: translateY(-40px);

  .demand-card-title {
    font-size: 24px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #222222;
    line-height: 32px;
  }
  .demand-card-return {
    display: flex;
    align-items: center;
    height: 26px;
    img,
    span {
      cursor: pointer;
    }
    span {
      font-size: 18px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #222222;
      line-height: 24px;
    }
    img {
      width: 24px;
      height: 24px;
      margin-right: 4px;
    }
  }

  .demand-detail-text {
    font-size: 16px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #222222;
    line-height: 24px;
    margin-top: 16px;
  }

  .demand-card {
    width: 100%;
    padding-top: 40px;
  }
  .demand {
    margin-right: 128px;
  }

  .demand-card-content {
    width: 100%;
  }

  .demand-card-selects {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 16px;
    margin-top: 32px;
  }

  .demand-option {
    width: 100%;
    height: 44px;
    background: #ffffff;
  }

  .demand-apply-btn {
    margin-top: 40px;
    width: 100%;
    height: 44px;
  }
  .phone-text {
    font-size: 14px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #606060;
    line-height: 22px;
    margin-top: 24px;
  }
  .phone-form {
    display: flex;
    margin-top: 16px;
    .phone-input {
      //   width: 240px;
      height: 44px;
      margin-right: 16px;
      :deep(.el-input__inner) {
        padding-left: 5px;
      }
    }
    .verifyCode-input {
      height: 44px;
      :deep(.el-input__inner) {
        padding-right: 85px;
        padding-left: 5px;
      }
    }

    .verifycode {
      position: relative;
      width: 100%;
      user-select: none;
      .verify-text {
        position: absolute;
        right: 16px;
        top: 7px;
        font-size: 14px;
        line-height: 30px;
        .verify-get {
          cursor: pointer;
          color: #ea5504;
        }
        .verify-wait {
          pointer-events: none;
          color: #888888;
        }
      }
    }
  }

  .footer-text {
    text-align: center;
    width: 100%;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #888888;
    line-height: 22px;
    margin-top: 16px;
  }

  .demand-info-list {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    .demand-info-item {
      margin-top: 48px;
      .info-title {
        font-size: 18px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #222222;
        line-height: 26px;
        margin-left: 12px;
      }
      .info-img {
        width: 16px;
        height: 16px;
      }
      .info-content {
        margin-left: 28px;
        margin-top: 12px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #222222;
        line-height: 22px;
      }
    }
  }

  .highlight {
    font-size: 16px;
    color: #ea5504;
  }
}
</style>
