<template>
	<transition name="fadeIn">
		<div class="toast" v-show="toast.isShow">
			<div class="toastbox">
				<p>
					 <i class="erro" v-if="this.toast.type!='success'"></i>
					 <i class="right" v-else></i>
				{{toast.txt}} </p>
			</div>
		</div>
	</transition>
</template>

<script>
	import useStore from '@/store/index.js'
	export default {
		data() {
			return {
			}
		},
		created(){
			this.store = useStore()
			this.toast = this.store.toast
		},
		methods:{
		}
	}
</script>

<style scoped>
	.toast {position: fixed;left: 0;top: 0;z-index: 100;width: 100%;height: 100%;}
	.toastbox {position: absolute;left: 50%;top: 50%;height: 76px;padding: 0 37px;line-height: 76px;;background: #fff;box-shadow: 0px 6px 20px 0px rgba(0, 0, 0, 0.1);border-radius: 6px;transform: translate(-50%,-50%);}
	.toastbox p {font-size: 16px;color: #222222;line-height: 76px;}
	.toastbox .erro {background-image: url(../assets/images/common/i_erro.png);width: 28px;height: 28px;display: inline-block;vertical-align: middle;margin-right: 10px;}
	.toastbox .right {background-image: url(../assets/images/common/i_right.png);width: 28px;height: 28px;display: inline-block;vertical-align: middle;margin-right: 10px;}
</style>
