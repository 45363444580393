<template>
	<div class="login">
		<div class="regbox ">
			<div class="regcont">
				<div class="tit">欢迎登录 <a v-show="loginCloseShow" @click="hideCopm" class="close"><img src="../assets/images/register/close.png" alt="" ></a></div>
				<div class="form">
					<div class="li"><input type="text" v-model="username" class="input1" placeholder="请输入手机号/邮箱"></div>
					<div class="li"><input type="password" v-model="password" class="input1" placeholder="请输入密码"></div>
					<div class="li flex">
						<a class="remember" :class="isremember? 'active' : '' "  @click="rememberPassword"><span class="checkbox "></span> 30天免登录</a>
						<a class="forget" @click="toForget">忘记密码</a>
					</div>
					<div class="li">
						<a  class="btn1" @click="login">登录</a>
					</div>
					<div class="li p1">
						还没有帐号？<a  class="link" @click="toRegister">立即注册</a>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	 import icom from '@/common/com.js';
	 import utils from '@/common/utils.js';
	 import useStore from '@/store/index.js'
	export default {
		data(){
			return {
				username:'',
				password:'',
				isremember:false,
			}
		},
		created(){
			this.store = useStore();
			
		},
		computed:{
			loginCloseShow(){
				return this.store.logins.loginCloseShow;
			}
		},
		methods:{
			hideCopm(){
				this.store.setCurComp('');
			},
			rememberPassword(){
				this.isremember = !this.isremember
				
			},
			toRegister(){
				this.store.setCurComp('register');
			},
			toForget(){
				this.store.setCurComp('forgetPassword');
			},
			login(){
				if(this.username==''){
					this.store.showToast('请输入手机号或者邮箱')
					return
				}
				let isEmail = this.username.includes('@');
				if(isEmail && !icom.checkStr(this.username,2)){
					this.store.showToast('请输入正确的手机号或者邮箱')
					return
				}
				if(!isEmail && !icom.checkStr(this.username)){
					this.store.showToast('请输入正确的手机号或者邮箱')
					return
				}
				
				if(this.password==''){
					this.store.showToast('请输入密码')
					return
				}
				// remember_me 30 天免登陆 0：否 1：是
				let remember_me = this.isremember? 1 : 0;
				this.API.login({username:this.username,password:this.password,remember_me:remember_me},(res)=>{
					console.log('登陆成功 ！')
					this.store.setCurComp('');
					
					utils.get_info(this.API,this.store,()=>{
						// this.$router.push({ path: '/person/infoset' })
						this.API.user_behavior_log({url:this.$route.path,opType:1},()=>{
							location.reload()
						})
					})
					
					
					// url	text	是		当前访问的网页的url
					// opType	text	是		操作类型 1：登录；2：搜索；3：收藏IP；4：取消收藏；5：访问页面
					// ipId	text	否		用户收藏的ip ID或者取消收藏的ip ID
					// searchInfo	text	否	['search' : "兰蔻", 'type': 1]	搜索信息
					
					
 
				}) 
			}
		},
		
		
	}
</script>

<style scoped>
	
</style>
