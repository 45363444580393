<template>
  <div class="home" @click="qrcode_hide">
    <!-- <img alt="Vue logo" src="../assets/logo.png"> -->
    <el-carousel
      class="ban"
      :key="bannerHeight + bannerList.length"
      :height="bannerHeight + 'px'"
      interval="5000"
      :class="{ hasDemand: hasDemand }"
    >
      <el-carousel-item
        v-for="item in bannerList"
        :key="item.image"
        class="banItem"
        :name="item.title"
      >
        <a :href="item.link" target="blank"
          ><img :src="item.image" alt="" class="banImg"
        /></a>
      </el-carousel-item>
      <!-- <el-carousel-item  class="banItem">
				<a to="/detail" data='{"iid":"1130"}'  @click="linkTo" ><img src="../assets/images/thum/ban5.png" alt="" class="banImg"></a>
			</el-carousel-item>
			<el-carousel-item  class="banItem">
				<a to="/detail" data='{"iid":"59"}'  @click="linkTo" ><img src="../assets/images/thum/ban1.jpg" alt="" class="banImg"></a>
			</el-carousel-item>
			<el-carousel-item  class="banItem">
				<a to="/detail" data='{"iid":"1238"}'  @click="linkTo" ><img src="../assets/images/thum/ban6.jpg" alt="" class="banImg"></a>
			</el-carousel-item>
			<el-carousel-item  class="banItem">
				<a to="/ip"  data='{"ipTypeLevel1":"4"}'   @click="linkTo" ><img src="../assets/images/thum/ban2.jpg" alt="" class="banImg"></a>
			</el-carousel-item>
			<el-carousel-item  class="banItem">
				<a to="/detail" data='{"iid":"1083"}'  @click="linkTo" ><img src="../assets/images/thum/ban4.jpg" alt="" class="banImg"></a>
			</el-carousel-item>
			<el-carousel-item  class="banItem">
				<a to="/detail" data='{"iid":"965"}'  @click="linkTo" ><img src="../assets/images/thum/ban3.jpg" alt="" class="banImg"></a>
			</el-carousel-item> -->
    </el-carousel>
    <!-- 热门IP推荐 -->
    <demandApply @applyDemandCallback="applyDemandCallback"></demandApply>
    <!-- 板块 -->
    <div class="bkbox">
      <div class="cont">
        <a
          to="/ip"
          :data="`{&quot;ipTypeLevel1&quot;:&quot;${index + 1}&quot;}`"
          @click="linkTo"
          class="item"
          v-for="(item, index) in [
            '明星艺人',
            '文化艺术',
            '动漫游戏',
            '垂直行业专家',
            '活动',
          ]"
          :key="item"
        >
          <img
            :src="
              require('../assets/images/index/bk_ico' + (index + 1) + '.png')
            "
            class="ico"
          />
          <span>{{ item }}</span>
        </a>
      </div>
    </div>
    <!-- 分享二维码 -->
    <!-- <el-affix position="bottom" offset="100"  target=".mainbox" z-index="5">
        <div class="sharebox">
          <img
            src="../assets/images/index/i_share1.png"
            alt=""
            class="i_share1"
            @click.stop="qrcode_show(1)"
          />
          <transition name="fadeInLeft">
            <qrcode ref="qrcode"></qrcode>
          </transition>
        </div>
      </el-affix> -->
    <!-- <div class="sharebox1">
          <img
            src="../assets/images/index/i_share1.png"
            alt=""
            class="i_share1"
            @click.stop="qrcode_show(1)"
          />
        </div> -->
    <!-- 板块列表 -->
    <div class="cont mainbox">
      <!-- 最新动态 -->
      <news></news>
      <!-- IP合作需求 -->
      <ipDemandList></ipDemandList>
      <!-- 热门IP推荐 -->
      <iplist></iplist>
      <!-- 明星艺人推荐 -->
      <starlist></starlist>
      <!-- 文化艺术 -->
      <culturelist></culturelist>

      <!-- 垂直行业专家 -->
      <expertlist></expertlist>

      <!-- 动漫游戏推荐 -->
      <comiclist></comiclist>
      <!-- 平台优势 -->
      <advantage></advantage>
    </div>

    <footers></footers>
  </div>
</template>

<script>
// @ is an alias to /src
import news from "@/views/home/news";
import iplist from "@/views/home/iplist";
import demandApply from "@/views/home/demandApply";
import ipDemandList from "@/views/home/ipDemandList";
import starlist from "@/views/home/starlist.vue";
import culturelist from "@/views/home/culturelist.vue";
import expertlist from "@/views/home/expertlist.vue";
import comiclist from "@/views/home/comiclist.vue";
import advantage from "@/views/home/advantage.vue";
import qrcode from "@/views/home/qrcode.vue";
import footers from "@/components/foot.vue";

import {
  defineComponent,
  getCurrentInstance,
  onBeforeMount,
  onBeforeUnmount,
  onMounted,
} from "vue";

export default {
  data() {
    return {
      screenWidth: "",
      bannerHeight: "",
      qrcodeVisible: false,
      bannerList: [],
      ratio: 1,
      hasDemand: true,
    };
  },
  components: {
    news,
    iplist,
    starlist,
    culturelist,
    expertlist,
    comiclist,
    advantage,
    footers,
    qrcode,
    demandApply,
    ipDemandList,
  },
  methods: {
    async getBannerList() {
      const res = await this.API.index_banner_list({});
      const { data, code } = res.data;
      if (code === 0) {
        this.bannerList = data;
      }
    },
    stopclick() {
      console.log(1);
    },
    qrcode_show(num, id) {
      window.open(
        "https://sqhdbjkjyxgs.qiyukf.com/client?k=46e002801592e2652de53873dee0b0b6&wp=1&robotShuntSwitch=0&shuntId=1095263&language=zh-cn"
      );
      // this.$refs.qrcode.showQrcode(num, id);
    },
    qrcode_hide() {
      this.$refs.qrcode?.hideQrcode?.();
    },
    linkTo(e) {
      let to = e.currentTarget.getAttribute("to");
      let data = e.currentTarget.getAttribute("data");
      this.utils.goto(this, to, JSON.parse(data));
    },

    setBanSize() {
      this.screenWidth =
        document.documentElement.clientWidth > 1366
          ? document.documentElement.clientWidth
          : 1366;
      this.bannerHeight = (480 / 1920) * this.screenWidth;
    },
    applyDemandCallback() {
      this.hasDemand = false;
    },
  },
  created() {
    this.getBannerList();
    // 缩放页面保证页面效果
    const resize = () => {
      const clientWidth = document.body.clientWidth;
      if (clientWidth < 1280) {
        return;
      }
      const widthRatio = clientWidth / 1366;
      let ratio = clientWidth >= 1366 ? 1 : widthRatio;
      // let marginTop = `${-(1080 * (1 - ratio)) / 2}px`;
      const root = document.documentElement;
      root.style.transformOrigin = "left top";
      root.style.transform = `scale(${ratio},1)`;
      this.ratio = ratio;
    };
    // resize();
    // window.onresize = resize;
  },
  mounted() {
    // banner 自适应
    this.setBanSize();
    window.addEventListener(
      "resize",
      () => {
        this.setBanSize();
      },
      false
    );
  },
  beforeUnmount() {},
  unmounted() {
    window.onresize = null;
  },
};
</script>
<style type="text/css" scoped>
.home {
  min-width: 1366px;
  transform-origin: left top;
}
.ban:deep(.el-carousel__button) {
  width: 45px;
  height: 4px;
  background: rgba(255, 255, 255, 0.9);
}
.hasDemand:deep(.el-carousel__indicators--horizontal) {
  bottom: 64px;
}
.ban .banImg {
  width: 100%;
}
.banItem a {
  width: 100%;
}
.bkbox .cont {
  display: flex;
  justify-content: space-between;
  margin-top: 44px;
}
.bkbox .item {
  width: 240px;
  height: 72px;
  background: #ffffff;
  box-shadow: 0px 4px 6px 0px rgba(117, 125, 135, 0.12);
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 4px solid transparent;
}
.bkbox .item:hover {
  border-bottom: 4px solid #f5630b;
  box-shadow: 0px 4px 6px 0px rgba(117, 125, 135, 0.12),
    0px 2px 2px 0px rgba(245, 99, 11, 0.24);
}
.bkbox .item .ico {
  width: 40px;
  height: 40px;
}
.bkbox .item span {
  font-size: 16px;
  margin-left: 5px;
  line-height: 22px;
  font-family: PingFangSC-Medium, PingFang SC;
}
/* 应用子组件 */
:deep(.title) {
  margin-top: 54px;
  margin-bottom: 30px;
}
:deep(.title h2) {
  font-size: 32px;
  color: #222222;
  padding-left: 20px;
  position: relative;
  float: left;
  font-weight: bold;
}
:deep(.title h2::before) {
  content: "";
  background: #f5630b;
  width: 4px;
  height: 20px;
  position: absolute;
  left: 0;
  top: 6px;
}
:deep(.title .more) {
  float: right;
  font-size: 14px;
  color: #13141c;
  margin-top: 20px;
  opacity: 0.5;
}
:deep(.listbox) {
  margin-bottom: 30px;
  padding-top: 1px;
}
:deep(.picbox) {
  overflow: hidden;
  border-radius: 4px;
}
:deep(.picbox .pic) {
  transition: transform 0.5s;
}
:deep(.picbox:hover .pic) {
  transform: scale(1.1);
}

.sharebox {
  z-index: 2;
  position: absolute;
  right: 0px;
  margin-top: 400px;
}
@media only screen and (max-width: 1600px) {
  .sharebox {
    right: 0px;
  }
}
.sharebox .i_share1 {
  width: 84px;
  height: 84px;
  cursor: pointer;
}
.sharebox .center {
  position: absolute;
  background: none;
}
.sharebox .center :deep(.cont1) {
  left: auto;
  right: 100px;
  transform: translate(0, 0);
  top: 0;
}
.sharebox1 {
  position: fixed;
  right: 100px;
  bottom: 100px;
  z-index: 20;
  cursor: pointer;
}
</style>
