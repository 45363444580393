<template>
  <el-affix :offset="0" class="headfix">
    <div class="header">
      <div class="cont">
        <div class="contbox">
          <div class="fl">
            <router-link to="/"
              ><img src="../assets/images/index/logo.jpg" alt="" class="logo"
            /></router-link>
          </div>
          <ul class="fl">
            <li :class="menuActive == 0 ? 'active' : ''">
              <router-link to="/">首页</router-link>
            </li>
            <li :class="menuActive == 1 ? 'active' : ''">
              <router-link to="/ip">IP筛选</router-link>
            </li>
            <li :class="menuActive == 2 ? 'active' : ''">
              <router-link to="/about">关于我们</router-link>
            </li>
          </ul>
          <div class="fr">
            <div class="searchBox" v-show="showSearch">
              <input
                type="text"
                class="input"
                placeholder="搜索IP关键词"
                @keyup.enter="search"
                v-model="keywords"
              />
              <a class="search" @click="search"></a>
            </div>
            <div class="station_message" v-show="logins.loginStatus">
              <el-popover placement="bottom" :width="288" trigger="click">
                <template #reference>
                  <div
                    @click="getList"
                    class="station_message_icon"
                    :class="isNotification ? 'station_message_unRead' : ''"
                  >
                    <img src="@/assets/images/index/message.png" />
                  </div>
                </template>
                <div class="message_list_wrap" v-loading="loading">
                  <div class="message_list">
                    <div
                      v-for="item in notificationList"
                      :key="item.id"
                      class="message_list_item"
                      @click="toDetail(item.ext.demand_id)"
                    >
                      <div class="message_list_item_title">
                        {{ item.ext.project_name }}
                      </div>
                      <div class="message_list_item_desc">
                        {{ item.ext.brand }} / {{ item.ext.budget_range }}
                      </div>
                    </div>
                  </div>
                </div>
              </el-popover>
            </div>
            <div class="fr" v-if="!logins.loginStatus">
              <a class="btn btn_login" @click="login">登录</a>
              <a class="btn btn_reg" @click="register">注册</a>
            </div>
            <div class="fr avtarbox" v-else @mouseleave="showDownMenu = false">
              <a @mouseenter="showDownMenu = true"
                ><img
                  src="@/assets/images/index/avtar.jpg"
                  alt=""
                  class="avtar"
              /></a>
              <transition name="fadeInDown">
                <div class="downmenu" v-show="showDownMenu">
                  <div class="topbox">
                    <img
                      src="@/assets/images/index/avtar.jpg"
                      alt=""
                      class="avtar avtar2"
                    />
                    <span class="userNickName">{{
                      logins.loginInfo.company == ""
                        ? logins.loginInfo.phone
                        : logins.loginInfo.company
                    }}</span>
                  </div>
                  <div class="items">
                    <router-link to="/person/baseinfo" class="item">
                      <img
                        src="../assets/images/index/i_member.jpg"
                        alt=""
                        class="i"
                      />
                      <p>个人中心</p>
                    </router-link>
                    <a class="item" @click="logout">
                      <img
                        src="../assets/images/index/i_logout.jpg"
                        alt=""
                        class="i"
                      />
                      <p>安全退出</p>
                    </a>
                  </div>
                </div>
              </transition>
            </div>
          </div>
        </div>
      </div>
    </div>
    <component
      :is="logins.curComp"
      @showComp="showComp"
      v-show="logins.curComp != ''"
    ></component>
  </el-affix>
</template>

<script>
import icom from "../common/com.js";
import utils from "../common/utils.js";
import register from "./register.vue";
import login from "./login.vue";
import authentication from "./authentication.vue";
import ipQuotaLimit from "./ipQuotaLimit.vue";
import useStore from "@/store/index.js";
import forgetPassword from "./forgetPassword.vue";
import signContract from "./signContract.vue";
import guide from "@/components/guide.vue";
import tracker from "@/common/tracker.js";
export default {
  data() {
    return {
      keywords: "",
      menuActive: 0,
      curComp: "",
      showSearch: true,
      showDownMenu: false,
      isRead: false,
      notificationList: [],
      loading: true,
    };
  },
  components: {
    register,
    login,
    authentication,
    forgetPassword,
    signContract,
    ipQuotaLimit,
    guide,
  },

  created() {
    this.store = useStore();
    this.logins = this.store.logins;
    // 如果code=5 代表未登录登陆状态
    // utils.get_info(this.API,this.store)

    // document.body.addEventListener('click', (event)=>{
    //   // 页面任何click事件发生首先会执行该函数，并且将event传给你了
    //   this.showDownMenu = false;
    // }, true);
  },
  methods: {
    async getList() {
      this.loading = true;
      try {
        await this.API.notification_list({}, (res) => {
          this.notificationList = res.data;
          this.isRead = 1;
        });
      } catch (e) {
        console.log(e);
      } finally {
        this.loading = false;
      }
    },
    toDetail(id) {
      this.$router.push(`/person/niche/detail?id=${id}`);
    },
    login() {
      this.store.setCurComp("login");
    },
    register() {
      this.store.setCurComp("register");
    },
    showComp(val) {
      this.store.setCurComp(val);
    },
    search() {
      if (this.keywords == "") {
        this.store.showToast("请输入关键词");
      } else {
        this.$router.push({ path: "/ip", query: { keywords: this.keywords } });
        tracker({
          url: this.$route.path,
          opType: 2,
          searchInfo: { search: this.keywords, type: 0 },
        });
        // this.API.user_behavior_log()
        this.keywords = "";
      }
    },
    logout() {
      // 退出接口
      this.API.logout({}, (res) => {
        this.store.Logout();
        // 判断在个人中心页面
        if (this.$route.path.includes("/person")) {
          this.$router.push({ path: "/" });
        }
      });
    },
  },
  watch: {
    // 这里监听路由改变菜单状态
    $route: function (e) {
      // console.log(e.path)
      if (e.path == "/") {
        this.menuActive = 0;
      } else if (e.path == "/ip") {
        this.menuActive = 1;
      } else if (e.path == "/privacy/settled") {
        this.menuActive = 2;
      } else if (e.path == "/privacy/privacy") {
        this.menuActive = 2;
      } else if (e.path == "/privacy/agreement") {
        this.menuActive = 2;
      } else if (e.path == "/about") {
        this.menuActive = 2;
      } else {
        this.menuActive = -1;
      }

      if (e.path == "/ip") {
        this.showSearch = false;
      } else {
        this.showSearch = true;
      }
    },
  },
  computed: {
    isNotification() {
      //监听用户信息的是否需要通知及内部定义的状态，支持手动更改
      return this.logins.loginInfo.notification && !this.isRead;
    },
  },
};
</script>

<style lang="less" scoped>
@import url("../assets/css/mixin");
.headfix {
  width: 100% !important;
  min-width: 1366px;
}
.header {
  background: #fff;
  height: 64px;
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.04);
}
.header .contbox {
  padding: 13px 0;
}
.header .logo {
  width: 88px;
  height: 25px;
  margin-top: 8px;
}
.header ul {
  margin-left: 50px;
}
.header ul li {
  display: inline-block;
  font-size: 16px;
  color: #0c0f1d;
  padding: 0px 20px;
  margin-right: 16px;
  height: 51px;
}
.header ul li a {
  display: inline-block;
  font-size: 16px;
  color: #0c0f1d;
  margin-top: 10px;
}
.header ul li.active {
  background: linear-gradient(
    180deg,
    rgba(235, 91, 5, 0) 40%,
    rgba(234, 85, 4, 0.1) 100%
  );
  position: relative;
}
.header ul li.active a,
.header ul li a:hover {
  color: #ea5504;
}
.header ul li.active::after {
  content: "";
  background: #ea5504;
  height: 4px;
  width: 100%;
  position: absolute;
  left: 0;
  bottom: 0px;
}
.header .searchBox {
  width: 422px;
  height: 38px;
  border-radius: 4px;
  border: 1px solid #8c93a9;
  float: left;
}
.header .searchBox .input {
  font-size: 14px;
  padding: 0 17px;
  width: 300px;
  height: 36px;
  vertical-align: top;
  border-radius: 4px;
}
.header .searchBox .search {
  width: 74px;
  height: 38px;
  background: url(../assets/images/index/i_search.png) no-repeat center;
  background-size: 20px auto;
  float: right;
}
.header .btn {
  width: 84px;
  height: 36px;
  border-radius: 4px;
  border: 1px solid #f5630b;
  color: #ea5504;
  font-size: 18px;
  line-height: 36px;
  text-align: center;
}
.header .btn_login {
  margin: 0 20px 0 44px;
}
.header .btn_reg {
  background: #f5630b;
  color: #fff;
}

.avtarbox {
  z-index: 2;
  position: relative;
}
.avtarbox .avtar {
  width: 38px;
  height: 38px;
  border-radius: 100%;
  margin-left: 32px;
}
.avtarbox .avtar2 {
  margin-left: 0;
  margin-right: 8px;
}
.avtarbox .topbox {
  padding: 14px 24px;
  background: url(../assets/images/index/infobg.jpg) no-repeat bottom;
}
.avtarbox .userNickName {
  font-size: 14px;
  color: #040404;
}
.avtarbox .downmenu {
  position: absolute;
  right: 0;
  top: 55px;
  width: 249px;
  height: 178px;
  background: #ffffff;
  box-shadow: 0px 6px 18px 0px rgba(0, 0, 0, 0.08);
  border-radius: 10px;
}
/* 用来mouseover 衔接 */
.avtarbox .downmenu::before {
  content: "";
  width: 100%;
  height: 20px;
  position: absolute;
  left: 0;
  top: -20px;
}
.avtarbox .downmenu .items {
  display: flex;
  text-align: center;
}
.avtarbox .downmenu .item {
  flex: 1;
  padding: 24px 0;
}
.avtarbox .downmenu .item p {
  font-size: 12px;
  color: #6b6b6b;
  margin-top: 8px;
}
.avtarbox .downmenu .item:hover p {
  color: #000;
}
.station_message {
  float: left;
  margin-left: 24px;
}
.station_message_icon {
  width: 40px;
  height: 40px;
  background: #f8f9fa;
  border-radius: 50%;
  cursor: pointer;
}
.station_message_icon > img {
  width: 40px;
  height: 40px;
}
.message_list_wrap {
  width: 288px;
  height: 370px;
  overflow-y: auto;
  margin-left: -12px;
  margin-top: -12px;
  padding: 0 12px;
}
.message_list {
  margin-top: 12px;
  height: 344px;
  padding: 4px;
  .scroll-style();
}
.message_list_item {
  padding-bottom: 16px;
  border-bottom: 1px solid #e3e6ec;
  margin-bottom: 14px;
  cursor: pointer;
  &:hover {
    .message_list_item_title {
      color: #ea5504;
    }
  }
}
.message_list_item .message_list_item_title {
  font-size: 16px;
  color: rgba(19, 20, 28, 0.9);
  line-height: 24px;
}
.message_list_item .message_list_item_desc {
  font-size: 14px;
  color: #888888;
  line-height: 22px;
  margin-top: 8px;
}
.message_list_item {
  &:last-child {
    margin-bottom: 0;
    border-width: 0;
  }
  &:first-child {
    margin-bottom: 14px;
    border-width: 1px;
  }
}
.station_message_unRead {
  position: relative;
  &::before {
    content: "";
    position: absolute;
    display: inline-block;
    width: 6px;
    height: 6px;
    background: #ff5555;
    border-radius: 50%;
    top: 7px;
    right: 7px;
  }
}

:deep(.regbox) {
  position: fixed;
  width: 100%;
  height: 100%;
  min-width: 1366px;
  background-color: rgba(0, 0, 0, 0.4);
  left: 0;
  top: 0;
  z-index: 10;
}
/* 登陆 */
:deep(.regcont) {
  background: #fff;
  width: 682px;
  height: 501px;
  border-radius: 8px;
  position: absolute;
  left: 50vw;
  top: 50vh;
  transform: translate(-50%, -50%);
  box-shadow: 0px 10px 24px 0px rgba(0, 0, 0, 0.1);
}
:deep(.regcont .tit) {
  border-bottom: rgba(158, 164, 186, 0.29) 1px solid;
  text-align: center;
  line-height: 62px;
  font-size: 18px;
}
:deep(.regcont .form) {
  width: 442px;
  margin: 0 auto;
  padding-top: 62px;
}
:deep(.regcont .li) {
  margin-top: 24px;
}
:deep(.regcont .input1) {
  width: 100%;
  height: 54px;
  border-radius: 6px;
  border: 1px solid #e3e6ec;
  padding: 0 26px;
  font-size: 16px;
}
:deep(.regcont .flex) {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}
:deep(.regcont .remember) {
  color: #888888;
  -webkit-user-select: none;
}
:deep(.regcont .checkbox) {
  background-image: url(../assets/images/register/i_checkbox.jpg);
  width: 14px;
  height: 14px;
  background-size: 14px auto;
  display: inline-block;
  vertical-align: middle;
}
:deep(.regcont .active .checkbox) {
  background-position: 0 -14px;
}
:deep(.regcont .btn1) {
  width: 100%;
  height: 54px;
  background: #f5630b;
  border-radius: 6px;
  line-height: 54px;
  text-align: center;
  color: #fff;
  font-size: 18px;
}
:deep(.regcont .p1) {
  text-align: center;
  color: #888888;
  font-size: 14px;
  margin-top: 31px;
}
:deep(.regcont .link) {
  color: #ea5504;
}
/* forgetPassword */
:deep(.regcont .verifyCode) {
  height: auto;
  overflow: hidden;
}
:deep(.regcont .verifyCode .input1) {
  width: 284px;
  float: left;
}
:deep(.regcont .verifyCode .btnSend) {
  width: 143px;
  height: 54px;
  background: #f5630b;
  border-radius: 6px;
  float: right;
  font-size: 16px;
  color: #fff;
  text-align: center;
  line-height: 54px;
}
:deep(.regcont .verifyCode .btnSend.disabled) {
  background: #f3f3f6;
  color: #222;
}
:deep(.regcont .mt46) {
  margin-top: 46px;
}
/* 关闭按钮 */
:deep(.regcont .close) {
  width: 50px;
  height: 50px;
  padding: 18px 15px;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 3;
}
:deep(.regcont .close img) {
  width: 17px;
  height: 17px;
}
</style>
