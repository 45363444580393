<template>
	<div class="footer">
		<div class="footnav">
			<div class="cont clearFix ">
				<div class="logoleft">
					<div class="logo1"></div>
					<p>链接IP方与品牌方<br>真实授权，真实数据<br>真实价格，真实案例</p>
				</div>
				<div class="footNavBox">
					<dl class="dl">
						<dt class="dt">关于</dt>
						<dd class="dd">
							<router-link to="/about?page=1">关于IP宇宙</router-link>
							<router-link to="/about?page=2">合作模式</router-link>
							<router-link to="/about?page=3">合作案例</router-link>
						</dd>
					</dl>
					<dl class="dl">
						<dt class="dt">条款</dt>
						<dd class="dd">
							<router-link to="/privacy/privacy">隐私协议</router-link>
							<router-link to="/privacy/agreement">用户协议</router-link>
							<router-link to="/privacy/settled">入驻协议</router-link>
						</dd>
					</dl>
					<dl class="dl">
						<dt class="dt">联系我们</dt>
						<dd class="dd">
							<p>邮箱：IP-yuzhou@social-touch.com</p>
							<p>电话：400-6911-961</p>
						</dd>
					</dl>
				</div>
				<div class="qrcodeBox">
					<div class="item">
						<img src="../assets/images/index/qrcode1.jpg" alt="" class="qrcode">
						<p class="txt">企业微信</p>
					</div>
					<div class="item">
						<img src="../assets/images/index/qrcode2.jpg" alt="" class="qrcode">
						<p class="txt">微信订阅号</p>
					</div>
				</div>
			</div>
		</div>
		<div class="footerCopy">
			<div class="cont">
				<p>Copyright © 2011-2024 时趣互动 版权所有</p>
				<p>京ICP备14034088号-8</p>
				<a class="cont_code" href="https://beian.mps.gov.cn/#/query/webSearch?code=11010502055421" rel="noreferrer" target="_blank">
					<img src="../assets/icon/badge.png"/>京公网安备11010502055421</a>
			</div>
		</div>
		
		
	</div>
	
	<transition name="fadeInLeft">
		<qrcode ref="qrcode"></qrcode>
	</transition>
</template>

<script>
	import qrcode from '@/views/home/qrcode.vue'
	export default {
		data(){
			return {
				
			}
		},
		components:{
			qrcode,
		},
		methods:{
			qrcode_show(num){
				this.$refs.qrcode.showQrcode(num);
			},
		}
	}
</script>

<style scoped>
	.logoleft{float: left;	}
	.footNavBox {float: left;}
	.logoleft p{font-size: 12px;color: #C3C3C5;line-height: 20px;margin-left: 36px;margin-top: 13px;width: 240px;}
	.logoleft .logo1{background-image: url(../assets/images/index/logo.png);width: 88px;height: 25px;background-size:100%;}
	.footNavBox .dl{margin-left: 78px;float: left;}
	.footNavBox .dl .dt{color: #fff;font-size: 14px;padding: 0 10px 10px 10px;}
	.footNavBox .dl .dd p{line-height: 30px;}
	.footNavBox .dl .dd a{color: #C3C3C5;display: block;font-size: 12px;padding: 10px;}
	.footNavBox .dl .dd a:hover{color: #fff;}
	.qrcodeBox {margin-left: 130px;float: left;}
	.qrcodeBox .item{float: left;margin-left: 40px;	}
	.qrcodeBox .qrcode{width: 110px;height: 110px;;}
	.qrcodeBox .item .txt{font-size: 12px;color: #C3C3C5;text-align: center;padding-top: 20px;	}
	.footer .footnav{background: #292C35;padding: 40px 0 60px;color: #C3C3C5;}
	.footer .footerCopy{background: #1D1F26;color: #9A9A9A;padding: 22px 0;}
	.footerCopy p{font-size: 12px;line-height: 17px;margin-right: 70px;display: inline-block;}
	.footer .cont {display: flex;}
	.cont_code {color: #9A9A9A;font-size: 12px;line-height: 17px;display: flex;align-items: center;}
	.cont_code >img{width: 20px;height: 20px;margin-right: 8px;}
</style>
