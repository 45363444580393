<template>
  <div v-show="newsList.length > 0 || isFirst" class="listbox" v-loading="loading">
    <div class="title clearFix list_box_title">
      <h2 class="news_title">
        最新动态<img src="@/assets/icon/icon_dynamic.png" />
      </h2>
      <div class="select_date_content">
        <!-- <el-select
        //切记打开时间切换组件时需要修改成现在isFirst逻辑
          v-model="selectValue"
          placeholder="请选择动态时间"
          class="select_date"
          popper-class="select_date_popper"
          size="large"
          @change="changeDate"
        >
          <el-option
            v-for="item in selectDateOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
            class="select_date_option"
          />
        </el-select> -->
      </div>
      <div class="pagination" v-show="pageTotal > 1">
        <span
          :class="{ pagination_pre: true, pagination_disable: page <= 1 }"
          @click="changePage('sub')"
          ><el-icon><ArrowLeftBold /></el-icon
        ></span>
        <span class="pagination_page">{{ page }}/{{ pageTotal }}</span>
        <span
          :class="{ pagination_next: true, pagination_disable: page >= pageTotal }"
           @click="changePage('add')"
          ><el-icon><ArrowRightBold /></el-icon
        ></span>
      </div>
    </div>
    <div
      :class="{
        listcont: true,
        clearFix: true,
        news_list: true,
        news_list_single: newsList.length <= 4,
      }"
    >
      <div class="item" v-for="item in newsList" v-bind:key="item.id" @click="linkTo(item.ip_id,'detailDynamic')">
        <img alt="" :src="item.cover_image" />
        <div class="item_content">
          <p class="item_title">{{ item.name }}</p>
          <div class="item_type">
            {{ dynamic_config?.[item.type].label
            }}{{
              +item.works_type
                ? `-${works_type_config[item.works_type]?.label}`
                : ""
            }}
            <span></span>
            {{date_text_config[item.type]}}：{{ formatDate(item.start_date)
            }}{{ item.end_date ? `-${formatDate(item.end_date)}` : "" }}
          </div>
          <div class="item_desc">
            {{ item.introduction }}
          </div>
          <div class="item_btn">
            点击查看详情<el-icon><ArrowRightBold /></el-icon>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { ArrowRightBold, ArrowLeftBold } from "@element-plus/icons-vue";
import { dynamic_config, works_type_config } from "@/common/enum";
import {formatDate} from '@/common/com'
import dayjs from "dayjs";
const format = "YYYY-MM-DD";
const static_date = [
  {
    label: "未来1个月",
    value: [
      dayjs().format("YYYY-MM-DD"),
      dayjs().add(1, "month").format(format),
    ].join(),
  },
  {
    label: "未来3个月",
    value: [
      dayjs().format("YYYY-MM-DD"),
      dayjs().add(3, "month").format(format),
    ].join(),
  },
  {
    label: "未来半年",
    value: [
      dayjs().format("YYYY-MM-DD"),
      dayjs().add(6, "month").format(format),
    ].join(),
  },
];
const dynamic_date = [1, 2, 3, 4, 5, 6].map((v) => {
  const currentDate = dayjs().add(v, "month");
  const year = currentDate.year();
  // 月份从0开始计算，所以+1
  const month = currentDate.month() + 1;
  return {
    label: `${year}年${month}月`,
    value: [
      currentDate.startOf("month").format(format),
      currentDate.endOf("month").format(format),
    ].join(),
  };
});
const date_text_config = {
  1:'活动时间',
  2:'上线时间',
  3:'发布时间'
}
export default {
  components: {
    ArrowRightBold,
    ArrowLeftBold,
  },
  data() {
    return {
      newsList: [],
      loading:true,
      selectDateOptions: [...static_date, ...dynamic_date],
      selectValue: static_date[2].value,
      page: 1,
      count: 0,
      pageSize: 8,
      dynamic_config,
      works_type_config,
      date_text_config,
      formatDate,
      isFirst:false,//只有第一次请求没有数据后才会隐藏当前模块
    };
  },
  computed: {
    params() {
      const date = this.selectValue.split(",");
      return {
        startDate: date[0],
        endDate: date[1],
        start:(this.page - 1) * this.pageSize,
        length:this.pageSize,
      };
    },
    pageTotal() {
      return Math.ceil(this.count / this.pageSize);
    },
  },
  created() {
    this.getList();
  },
  methods: {
    changePage(type){
      if(type === 'sub' &&this.page > 1  ){
        this.page = this.page - 1
      }else if(type === 'add' && this.page < this.pageTotal){
        this.page = this.page + 1
      }
      this.getList()
    },
    linkTo(e) {
      let to = e.currentTarget.getAttribute("to");
      let data = e.currentTarget.getAttribute("data");
      this.utils.goto(this, to, JSON.parse(data));
    },
    async getList() {
      this.loading = true
      await  this.API.index_dynamic_list(this.params, (res) => {
        const { code, data } = res;
        if (code === 0) {
          this.count = data.count;
          if(!this.isFirst){
            this.isFirst =  +data.count > 0
          }
          this.newsList = data.data
        }
      });
      this.loading = false
    },
    changeDate() {
      this.getList();
    },
    linkTo(iid, activeName){
      this.utils.goto(this,'/detail',{iid,activeName})
    },
  },
};
</script>

<style scoped>
.news_title > img {
  width: 32px;
  height: 32px;
  margin-left: 8px;
}
.news_list {
  display: grid;
  grid-template-columns: repeat(4, 329px);
  grid-template-rows: repeat(2, 274px);
  grid-row-gap: 16px;
  grid-column-gap: 16px;
}
.news_list_single {
  grid-template-rows: repeat(1, 274px);
}
.item {
  /* min-height: 274px; */
  background: #ffffff;
  border-radius: 4px;
  border: 1px solid #dbdee9;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  position: relative;
  left: 0;
  top: 0;
}
.item:hover {
  width: 411px;
  height: 464px;
  left: -42px;
  top: -16px;
  z-index: 11;
  box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.06);
}
.item:hover > img {
  height: 236px;
}
.item > img {
  width: 100%;
  height: 190px;
  background: #d8d8d8;
  border-radius: 4px;
  object-fit: cover;
}
.item_content {
  flex: 1;
  padding: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
}
.item_title {
  height: 52px;
  font-size: 18px;
  color: #222222;
  line-height: 26px;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}
.item_type {
  display: none;
  align-items: center;
  margin: 8px 0 12px 0;
  font-size: 14px;
  color: rgba(19, 20, 28, 0.9);
  line-height: 22px;
  /* justify-content: space-between; */
  font-family: PingFangSC-Regular, PingFang SC;
}

.item_type > span {
  width: 1px;
  height: 12px;
  background: rgba(158, 164, 186, 0.32);
  margin: 0 9px;
}
.item_desc {
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  font-size: 14px;
  color: rgba(19, 20, 28, 0.9);
  line-height: 22px;
  height: 66px;
  display: none;
}
.item_btn {
  display: none;
  font-size: 14px;
  color: #f5630b;
  line-height: 22px;
  margin-top: 12px;
  align-items: center;
}
.item:hover .item_type {
  display: flex;
}
.item:hover .item_btn {
  display: flex;
}
.item:hover .item_desc {
  display: -webkit-box;
}
.list_box_title {
  width: 100%;
  display: flex;
}
.select_date_content {
  flex: 1;
  display: flex;
  justify-content: flex-end;
}

/* .select_date_option >span{
  color:#F5630B
} */
</style>
<style lang="less">
.select_date {
  width: 258px;
}
.pagination {
  margin-left: 32px;
  display: flex;
  align-items: center;
  font-family: PingFangSC-Regular, PingFang SC;
  user-select: none;
  .pagination_pre,
  .pagination_next {
    width: 40px;
    height: 40px;
    background: #ffffff;
    border-radius: 4px;
    border: 1px solid #e3e6ec;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    > i {
      font-size: 18px;
      color: rgba(19, 20, 28, 0.9);
      > svg {
        font-size: 18px;
      }
    }
  }
  .pagination_page {
    font-size: 14px;
    color: rgba(19, 20, 28, 0.9);
    margin: 0 9px;
  }
  .pagination_disable {
    cursor: no-drop;
    > i {
      color: #bbbbbd;
    }
  }
}
</style>
