const defaultUrl = '/api/log/user-behavior-log';
const tracker = (data,url = defaultUrl) => {
  // console.log(data)
  // const blob = new Blob([JSON.stringify(data)],{
  //   type:'application/x-www-form-urlencoded'
  // })
  const formData = new FormData();
  Object.keys(data).forEach((key) => {
    let value = data[key];
    if(typeof value !== 'string'){
      value = JSON.stringify(value)
    }
    formData.append(key,value)
  })
  navigator?.sendBeacon(url,formData);
};
export default tracker