import icon_weibo from "@/assets/icon/platform/icon_weibo.png";
import icon_dy from "@/assets/icon/platform/icon_dy.png";
import icon_xhs from "@/assets/icon/platform/icon_xhs.png";
import icon_bz from "@/assets/icon/platform/icon_bz.png";
import icon_ks from "@/assets/icon/platform/icon_ks.png";
import icon_wx from "@/assets/icon/platform/icon_wx.png";
import icon_zh from "@/assets/icon/platform/icon_zh.png";
import icon_qt from "@/assets/icon/platform/icon_qt.png";
export const PLATFORM_OPTIONS = [
  {
    icon: icon_weibo,
    label: "微博",
    value: 1,
  },
  {
    icon: icon_dy,
    label: "抖音",
    value: 3,
  },
  {
    icon: icon_xhs,
    label: "小红书",
    value: 5,
  },
  {
    icon: icon_bz,
    label: "B站",
    value: 6,
  },
  {
    icon: icon_ks,
    label: "快手",
    value: 4,
  },
  {
    icon: icon_wx,
    label: "微信",
    value: 2,
  },
  {
    icon: icon_zh,
    label: "知乎",
    value: 7,
  },
  {
    icon: icon_qt,
    label: "其他",
    value: 99,
  },
];
export const platform_config = PLATFORM_OPTIONS.reduce((init, current) => {
  init[current.value] = current;
  return init;
}, {});
export const DYNAMIC_TYPE = [
  {
    value: '1',
    label: "活动",
  },
  {
    value: '2',
    label: "作品",
  },
  {
    value: '3',
    label: "新图库",
  },
];
export const WORKS_TYPE = [
  {
    value: '1',
    label: "电影",
  },
  {
    value: '2',
    label: "舞台剧",
  },
  {
    value: '3',
    label: "电视剧",
  },
  {
    value: '4',
    label: "综艺",
  },
  {
    value: '5',
    label: "音乐",
  },
  {
    value: '6',
    label: "商务动态",
  },
  {
    value: '99',
    label: "其他",
  },
];
export const dynamic_config = DYNAMIC_TYPE.reduce((init, current) => {
  init[current.value] = current;
  return init;
}, {});
export const works_type_config = WORKS_TYPE.reduce((init, current) => {
  init[current.value] = current;
  return init;
}, {});
