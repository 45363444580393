<template>
	<div class="center"  v-show="qrcodeVisible" @click="hideQrcode" >
		<div class="cont1">
			<div class="sharecont"  @click.stop>
				<div class="tit" >
					<a  class="close"  @click="hideQrcode">×</a>
					<h2 class="titTxt">
						<template v-if="showNum==0">在线客服</template>
						<template v-if="showNum==1">在线客服</template>
						<template v-else>分享二维码</template>
					</h2>
				</div>
				<div class="qrcodebox">
					<div class="item" v-show="showNum==1 ">
						<img src="@/assets/images/index/qrcode1.jpg" alt="" class="qrcode">
						<p>任何问题欢迎咨询</p>
						<p>扫码添加客服微信</p>
					</div>
					<div class="item"  v-show="showNum==2 ">
						<div id="qrCode1" class="qrCode1" ref="qrCodeDiv"></div>
						<p>扫码分享给朋友使用</p>
					</div>
				</div>
			</div>
		</div>
	</div>
	
</template>

<script>
	import QRCode from 'qrcodejs2';
	export default{
		data(){
			return {
				qrcodeVisible:false,
				showNum:1,//默认为  1 是客服  2 是分享二维码
			}
		},
		methods:{
			hideQrcode(){
				this.qrcodeVisible = false;
			},
			bindQRCode (id) {
				
				this.$refs.qrCodeDiv.innerHTML = "";
				let domain = window.location.origin
			    new QRCode(this.$refs.qrCodeDiv, {
			      text: domain+'/h5/detail?iid='+id,
			      width: 200,
			      height: 200,
			      colorDark: "#333333", //二维码颜色
			      colorLight: "#ffffff", //二维码背景色
			      correctLevel: QRCode.CorrectLevel.L//容错率，L/M/H
			    })
			},
			showQrcode(num,id){
				if(num ==2){
					this.bindQRCode(id)
				}
				this.showNum = num
				this.qrcodeVisible = true;
			}
		}
		
	}
</script>

<style scoped>
	.center{position: fixed;width: 100%;height: 100%;left: 0;top:0;z-index: 1000;background: rgba(0,0,0,0.5);}
	.center .cont1{position: absolute;left: 50%;top:50%;transform: translate(-50%,-50%);width: 554px;height: 373px;}
	.sharecont{background: #fff;border-radius: 19px;box-shadow: 0px 10px 24px 0px rgba(0, 0, 0, 0.1);position: absolute;left:0;top:0;}
	.sharecont .tit{width: 554px;height: 66px;background: #1FD2A6;border-radius: 19px 19px 0px 0px;color: #fff;padding: 10px 0 10px 30px;box-sizing: border-box;}
	.sharecont .tit .close{float: right;color: #fff;font-size: 22px;line-height: 46px;;padding: 0 20px;}
	.sharecont .tit .titTxt{font-size: 16px;line-height: 46px;float: left;}
	.sharecont .qrcodebox{display: flex;padding: 54px 0 65px;}
	.sharecont .qrcodebox .item{text-align: center;flex: 1;}
	.sharecont .qrcodebox .item .qrcode{margin-bottom: 12px;width: 152px;height: 152px;}
	.sharecont .qrcodebox .item .qrCode1{margin-bottom: 12px;width: 152px;height: 152px;display: inline-block;}
	.sharecont .qrcodebox .item  :deep(img){width: 152px;height: 152px;}
	.sharecont .qrcodebox .item p{font-size: 12px;color: #888888;line-height: 18px;}
</style>