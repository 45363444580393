import {
  createRouter,
  createWebHistory,
  createWebHashHistory,
} from "vue-router";

import home from "@/views/home.vue";
import { API } from "@/api/index";
import useStore from "@/store/index.js";
import utils from "../common/utils.js";

const routes = [
  {
    path: "/",
    name: "index",
    component: home,
    meta: { title: "IP宇宙" },
  },
  {
    path: "/ip",
    name: "ip",
    meta: { title: "IP筛选" },
    component: () => import("@/views/ip.vue"),
  },
  {
    path: "/h5/detail",
    name: "h5",
    meta: { title: "IP宇宙" },
    component: () => import("@/h5/detail.vue"),
  },
  {
    path: "/detail",
    name: "detail",
    meta: { title: "IP宇宙" },
    component: () => import("@/views/IpDetail.vue"),
  },
  {
    path: "/about",
    name: "about",
    component: () => import("@/views/about.vue"),
    meta: { title: "关于我们" },
  },
  {
    path: "/privacy",
    name: "privacyindex",
    redirect: "/privacy/privacy",
    component: () => import("@/views/privacy/index.vue"),
    // meta:{title:"隐私政策"},
    children: [
      {
        path: "privacy",
        name: "privacy",
        component: () => import("@/views/privacy/privacy.vue"),
        meta: { title: "隐私政策" },
      },
      {
        path: "agreement",
        name: "agreement",
        component: () => import("@/views/privacy/agreement.vue"),
        meta: { title: "用户协议" },
      },
      {
        path: "settled",
        name: "settled",
        component: () => import("@/views/privacy/settled.vue"),
        meta: { title: "入驻协议" },
      },
    ],
  },

  {
    path: "/person",
    name: "person",
    component: () => import("@/views/person/index.vue"),
    meta: { title: "个人中心" },
    children: [
      {
        path: "baseinfo",
        name: "baseInfo",
        component: () => import("@/views/person/baseInfo.vue"),
        meta: { title: "信息认证", keepAlive: true },
      },
      {
        path: "addlist",
        name: "addlist",
        component: () => import("@/views/person/addList.vue"),
        meta: { title: "收藏列表", keepAlive: false },
      },
      {
        path: "management",
        name: "management",
        component: () => import("@/views/person/ipManagement/index.vue"),
        meta: { title: "IP管理", keepAlive: false },
      },
      {
        path: "management/add",
        name: "add",
        component: () =>
          import("@/views/person/ipManagement/addEdit/index.vue"),
        meta: {
          title: "IP管理",
          keepAlive: false,
          activePath: "/person/management",
        },
      },
      {
        path: "demand",
        name: "demand",
        component: () => import("@/views/person/demand.vue"),
        meta: { title: "需求管理", keepAlive: false },
      },
      {
        path: "niche",
        name: "niche",
        component: () => import("@/views/person/niche.vue"),
        meta: { title: "商机响应", keepAlive: false },
      },
      {
        path: "niche/detail",
        name: "nicheDetail",
        component: () => import("@/views/person/nicheDetail.vue"),
        meta: { title: "商机响应", keepAlive: false,activePath: "/person/niche" },
      },
      {
        path: "infoset",
        name: "infoSet",
        component: () => import("@/views/person/infoSet.vue"),
        meta: { title: "安全设置", keepAlive: false },
      },
      {
        path: "infoset/bindtel",
        name: "bindtel",
        component: () => import("@/views/person/bindTel.vue"),
        meta: { title: "绑定手机号", activePath: "/person/infoset" },
      },
      {
        path: "infoset/modifyemail",
        name: "modifyEmail",
        component: () => import("@/views/person/modifyEmail.vue"),
        meta: { title: "修改邮箱", activePath: "/person/infoset" },
      },
      {
        path: "infoset/modifypassword",
        name: "modifyPassword",
        component: () => import("@/views/person/modifyPassword.vue"),
        meta: { title: "修改密码", activePath: "/person/infoset" },
      },
    ],
  },
];

const router = createRouter({
  // history: createWebHashHistory(process.env.BASE_URL),
  history: createWebHistory(process.env.BASE_URL),
  routes,
});
// 个人中心页面
// const whiteList = '/person';
var isFirst = true;
router.beforeEach((to, from, next) => {
  var store = useStore();
  window.scrollTo(0, 0);
  if (to.meta.title) {
    document.title = to.meta.title;
  }
  // 5访问页面
  API.user_behavior_log({ url: to.path, opType: 5 });
  // 判断登录状态是否有效 true 可以登陆
  if (isFirst) {
    // 第一次登录页面还未请求接口 此时状态必然为默认值 false 先调用获取getinfo接口 存储 用户登录状态
    isFirst = false;
    utils.get_info(API, store, (res) => {
      // 已登录
      reDircto(to, next);
    });
  } else {
    reDircto(to, next);
  }
});

const reDircto = (to, next) => {
  var store = useStore();
  if (!store.logins.loginStatus) {
    // 未登录
    if (to.path.includes("/ip")) {
      store.setCurComp("login");
      store.setLoginClose(false);
      next();
    } else {
      store.setLoginClose(true);
      if (to.path.includes("/person")) {
        // 没有登陆授权 都重定向到首页
        next("/"); // 否则全部重定向到登录页
      } else {
        next();
      }
    }
  } else if (store.logins.checkStatus != 2) {
    next();
    if (to.path.includes("/ip")) {
      store.setCurComp("authentication");
      store.setLoginClose(false);
      next();
    } else {
      store.setLoginClose(true);
      next();
    }
  } else {
    next();
  }
};

export default router;
