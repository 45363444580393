import { createApp } from 'vue'

import App from './App.vue'
import router from './router'
import ElementPlus from 'element-plus' 

import axios from 'axios'
import 'element-plus/dist/index.css'
import zhCn from 'element-plus/lib/locale/lang/zh-cn'
import '@/assets/css/common.css'

import { API } from '@/api/index'
import utils from '@/common/utils.js';
import { createPinia } from 'pinia'
import '@/assets/style/element/index.scss'
const app = createApp(App)
app.use(createPinia())
// 把 API ，bus 挂载全局变量到实例上
API.init()
app.config.globalProperties.API = API;
app.config.globalProperties.utils = utils;


app.use(ElementPlus,{
  locale:zhCn
}).use(router).mount('#app');