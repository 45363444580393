<template>


	<div class="register">
		<div class="regbox ">
			<div class="regcont">
				<a v-show="loginCloseShow"  @click="hideCopm"  class="close"><img src="../assets/images/register/close.png" alt="" ></a>
				<el-tabs v-model="activeName" class="regTabs" >
					
					<el-tab-pane label="品牌方" name="brand">
						
					</el-tab-pane>
					<el-tab-pane label="IP方" name="ip">
						<!-- <div class="form">
							<div class="qrcode"><img src="../assets/images/index/qrcode1.jpg" alt="" class="img"></div>
							<p class="txt1">扫码添加客服企业微信</p>
							<p class="txt1">获取在线客服解答服</p>
						</div> -->
					</el-tab-pane>
					<el-tab-pane label="其他" name="other">
					</el-tab-pane>
				</el-tabs>
				<div class="form form1">
							<div class="li"><input type="text" v-model="phone" class="input1" placeholder="请输入手机号" maxlength="11"></div>
							<div class="li"><input type="password" v-model="password" class="input1" placeholder="请输入不少于8位的密码，需包含字母数字"></div>
							<div class="li"><input type="password" v-model="repeatPassword" class="input1" maxlength="16"  placeholder="请确认密码"></div>
							<div class="li verifyCode">
								<input type="text" class="input1" v-model="code" placeholder="请输入验证码" maxlength="6">
								<a  class="btnSend" @click="getCode" ref="btnSend">获取验证码</a>
							</div>
							<div class="li">
								<a  class="btn1" @click="register">立即注册</a>
							</div>
							<div class="li p1">
								已有帐号？<a  class="link" @click="toLogin">立即登录</a>
							</div>
							<!-- <div class="li p1">目前仅支持品牌方在线注册</div> -->
						</div>
			</div>
		</div>
	</div>



</template>

<script>
	import icom from '../common/com.js';
	import useStore from '@/store/index.js'
	const register_config = {
			brand:1,
			ip:2,
			other:99
	}
	export default {
		data() {
			return {
				activeName: 'brand',
				password:'',
				repeatPassword:'',
				phone:'',
				code:'',
			}
		},
		created(){
			this.store = useStore()
		},
		computed:{
			loginCloseShow(){
				return this.store.logins.loginCloseShow;
			}
		},
		methods:{
			hideCopm(){
				this.store.setCurComp('');
			},
			register(){
				const registerType= register_config[this.activeName];
				let isPhone = this.checkPhone();
				if(!isPhone) return
				if(this.password==''){
					this.store.showToast('请输入登录密码')
					return
				}
				if(!icom.checkStr(this.password,11)){
					this.store.showToast('请输入与大于8位小于16位并且包含字母数字的密码')
					return
				}
				
				if(this.repeatPassword==''){
					this.store.showToast('请输入确认密码')
					return
				}
				if(this.repeatPassword!=this.password){
					this.store.showToast('确认密码与密码不一致')
					return
				}
				if(this.code==''){
					this.store.showToast('请输入验证码')
					return
				}
				
				// 提交注册接口
				this.API.register({phone:this.phone,password:this.password,code:this.code,type:registerType},(res)=>{
					
					this.store.setCurComp('login');
				})
				
			},
			getCode(){
				let isPhone = this.checkPhone();
				if(!isPhone) return
				// 1：注册；2：忘记密码 3：修改密码
				this.API.send_msg_code({phone:this.phone,source:1},(res)=>{
					icom.countdown(this.$refs.btnSend,60);
				})
				
				
			},
			checkPhone(){
				if(this.phone==''){
					this.store.showToast('请输入手机号')
					return false
				}
				// 未注册时候
				// 该手机号未注册
				if(!icom.checkStr(this.phone)){
					this.store.showToast('请输入正确的手机号')
					return false
				}
				return true
			},
			toLogin(){
				this.store.setCurComp('login');
			},
		}


	}
</script>

<style scoped>
	.register :deep(.el-tabs__nav){text-align: center;float: none;}
	.register :deep(.el-tabs__item ){height: 62px;line-height: 62px;width: 165px;}
	.register :deep(.el-tabs__item.is-active){color: #F5630B;}
	.register :deep(.el-tabs__item:hover){color: #F5630B;}
	.register :deep(.el-tabs__active-bar){background: #F5630B;}
	.register .regcont{height: 616px;}
	.register .regcont .form1{padding-top:0px;}
	.register .qrcode{background: #F4F4F4;width: 300px;height: 300px;margin: 0 auto 30px;padding: 25px;;}
	.register .img{width: 250px;;}
	.register .txt1{font-size: 14px;color: #0B0B0B;text-align: center;margin-bottom: 12px;line-height: 20px;}
</style>
