<template>
	<headers ref="head" v-if = 'isHide' v-cloak/>	
	<router-view />
	<!-- 消息提示 -->
	<toast v-if = 'isHide' v-cloak />
	
</template>
<script type="text/javascript">
	import headers from '@/components/head.vue'
	import toast from '@/components/toast.vue'
	import {
		defineComponent,
		getCurrentInstance,
		onBeforeMount,
		onBeforeUnmount,
		onMounted
	} from 'vue'
	export default {
		components: {
			headers,
			toast,
		},
		data(){
			return {
				isHide:true,
			}
		},
		watch:{
			$route(){
				if(this.$route.path=='/h5/detail'){
					this.isHide = false;
				}else{
					this.isHide = true;
				}
				
			},
		},
		methods:{
			showLogin(){
				this.$refs.head.login();
			}
		}
	}
</script>
<style lang="less">
	 [v-cloak]{
		display: none
	}
	body {background: #f8f9fa;}
</style>
