<template>
	<div class="listbox">
		<div class="title clearFix">
			<h2 >平台优势</h2>
		</div>
		<div class="listcont  advantage">
			<div class="item">
				<div class="ico ico1"></div>
				<div class="t1">一手直联</div>
				<div class="t2">真实授权，真实价格</div>
			</div>
			<div class="item">
				<div class="ico ico2"></div>
				<div class="t1">汇集全球IP</div>
				<div class="t2">全品类IP覆盖，提升IP合作效率</div>
			</div>
			<div class="item">
				<div class="ico ico3"></div>
				<div class="t1">大数据精准分析</div>
				<div class="t2">AI+数据赋能，让传播有数可依</div>
			</div>
			<div class="item">
				<div class="ico ico4"></div>
				<div class="t1">专业高效</div>
				<div class="t2">IP营销专业团队，提供一站式服务</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default{
		data(){
			return {
				
			}
		}
	}
</script>

<style scoped>
	.advantage {display: flex;flex-wrap: wrap;justify-content: space-between;margin-bottom: 168px;}
	.advantage .ico {width: 60px;height: 60px;}
	.advantage .ico1 {background-image: url(../../assets/images/index/atv_ico1.png);}
	.advantage .ico2 {background-image: url(../../assets/images/index/atv_ico2.png);}
	.advantage .ico3 {background-image: url(../../assets/images/index/atv_ico3.png);}
	.advantage .ico4 {background-image: url(../../assets/images/index/atv_ico4.png);}
	.advantage .item{width: 307px;height: 233px;background: #FFFFFF;border-radius: 4px;border: 1px solid #EAEAEA;text-align: center;justify-content: center;align-items: center;display: flex;flex-direction: column;}
	.advantage .item:hover{box-shadow: 0px 16px 40px 0px rgba(0, 0, 0, 0.06);}
	.advantage .t1{font-size: 16px;color: #222222;line-height: 22px;margin-top: 25px;}
	.advantage .t2{font-size: 12px;color: #686868;line-height: 17px;margin-top: 13px;}

</style>