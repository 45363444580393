<template>
	<div class="listbox">
		<div class="title clearFix">
			<h2 >明星艺人推荐</h2>
			<a to="/ip" data='{"ipTypeLevel1":"1"}'  @click="linkTo" class="more">查看更多 ></a>
		</div>
		<div class="listcont clearFix starlist">
			<div class="list">
				<div class="item" v-for="item in starlist">
					<!-- "{'iid':" +item.id+ "}" -->
					<a to="'/detail" :data=' `{"iid":"${item.id}" }` ' class="link" @click="linkTo">
						<div class="picbox"><img :src="item.horizontal_img"  alt="" class="pic"></div>
					</a>
					<a  to="'/detail" :data=' `{"iid":"${item.id}" }` ' class="link" @click="linkTo">
						<p class="tit">{{item.name}}</p>
						<p class="subtit">{{item.desc}}</p>
					</a>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import useStore from '@/store/index.js'
	export default{
		data(){
			return {
				starlist:[]
			}
		},
		created(){
			this.store = useStore();
			 this.API.star_list({limit:10},(res)=>{
				  this.starlist = res.data
			 })
			 console.log(3333,this.API);
			 
		},
		methods:{
			linkTo(e){
				let to = e.currentTarget.getAttribute("to")
				let data = e.currentTarget.getAttribute("data")
				this.utils.goto(this,to,JSON.parse(data))
			},
		}
		
	}
</script>

<style scoped>
	.starlist .list{display: flex;flex-wrap: wrap;}
	.starlist .list .item{width: 260px;margin-bottom: 15px;margin-right: 16px;;}
	.starlist .list .item:nth-child(5n){margin-right: 0;}
	.starlist .list .picbox{width: 260px;height: 142px;}
	.starlist .list .picbox .pic{width: 260px;height: 149px;object-fit: cover;}
	.starlist .list a.link{display: block;}
	.starlist .list .tit{font-size: 16px;color: #222222;line-height: 22px;padding-top: 12px;padding-left: 8px;overflow: hidden;text-overflow: ellipsis;white-space: nowrap;}
	.starlist .list .subtit{font-size: 14px;color: #888888;line-height: 20px;padding-top: 8px;padding-left: 8px;overflow: hidden;text-overflow: ellipsis;white-space: nowrap;}
</style>