import { defineStore } from 'pinia'
import icom from "@/common/com.js";
// 对外部暴露一个 use 方法，该方法会导出我们定义的 state
const useStore = defineStore({
	// 每个 store 的 id 必须唯一
	id: 'useStore',
	// state 表示数据源
	state: () => ({
		// 提示
		toast:{
			txt: '',
			type:'',
			isShow:false
		},
		// 登陆状态
		logins:{
			loginStatus:false,
			checkStatus:'',//认证状态
			loginCloseShow:true,
			checkText:'认证通过后即可解锁更多内容',//未认证弹窗提示文案
			loginInfo:{},
			curComp: '',//头部当前显示的登陆组件 login register forgetpassword 签署合同：signContract，认证：authentication,ip查看限制 ipQuotaLimit,引导登录注册 guide
		},
		name:{},
		type: 0,// 用户注册类型，1品牌、2ip、3其他
	}),
	// getters 类似于 computed，可对 state 的值进行二次计算
	getters: {
		toastTxt() {
			// getter 中的 this 指向👉 state
			return this.toast.txt
		},
		
	},
	// actions 用来修改 state
	actions: {
		// 显示提示并传值
		showToast(val,success) {
			this.toast.txt = val;
			this.toast.isShow = true;
			if(success){
				this.toast.type = success;
			}else{
				this.toast.type = '';
			}
			
			setTimeout(this.hideToast,1000)
		},
		hideToast(){
			this.toast.isShow = false;
			// this.toastTxt = '';
		},
		Login(){
			this.logins.loginStatus = true;
		},
		Logout(){
			this.logins.loginStatus = false;
		},
		setCheck(res){
			this.logins.checkStatus = res;
		},
		setType(res){
			this.logins.type = res;
		},
		setLoginClose(res){
			this.logins.loginCloseShow = res;
			this.logins.checkText = '认证通过后即可解锁更多内容'
		},
		setIPManageStatus(text){
			//未认证
			if(this.logins.checkStatus != 2){
				this.logins.loginCloseShow = true
				this.logins.checkText = text
				this.logins.curComp = 'authentication';
				return false
			}
			return true
		},
		setContract(){
			//签署合同
			if(!this.logins.loginInfo['basic_info'].contract){
				this.logins.curComp = 'signContract';
				return false
			}
			return true
		},
		setloginInfo(res){
			this.logins.loginInfo = res
		},
		setCurComp(res){
			this.logins.curComp = res
		}
		
	}
})


export default useStore
