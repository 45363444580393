import axios from "axios";
import useStore from "@/store/index.js";
import Qs from "qs";
import icom from "@/common/com.js";
import { ElMessage } from "element-plus";
var API = {
  doMain: process.env.NODE_ENV == "development" ? "http://localhost:8081" : "",
  // https://ip-univers.com
  // http://d.ip-universe.com 测试域名
  init() {
    API.requestDomain = this.doMain + "/api/";
    API.img_upload = API.requestDomain + "common/img_upload";
    API.file_upload = API.requestDomain + "common/file_upload";
    API.pageSize = 6;
    this.store = useStore();
    // API.file_upload_f = API.requestDomain + "common/file_upload_f";
    // API.orderTemplate = this.doMain + "file/";
  },
  // file_upload:API.requestDomain + "cmmmon/file_upload",
  axiosapi(name, method, data, cb) {
    var method = method || "POST";

    if (method == "GET") {
      return axios
        .get(this.requestDomain + name, {
          params: data,
          headers: {
            "content-type": "application/x-www-form-urlencoded", // 默认值
            myToken: icom.getCookie("myToken"),
          },
        })
        .then((res) => {
          if (res.data.code == 0) {
            cb?.(res.data);
            // code 5 代表未登录 在个人中心页面需要返回首页
          } else if (res.data.code == 5) {
            // if (cb) cb(res.data);
            this.store.Logout();
          } else if (res.data.code == 15) {
            //code等于15表示查看ip数量超出限制，不做msg提示
          } else {
            ElMessage({
              message: res.data.msg,
              type: "error",
            });
          }
          return res;
        });
    } else {
      var headers = {
        headers: {
          myToken: icom.getCookie("myToken"),
          "content-type": "application/x-www-form-urlencoded",
        },
      };
      return axios
        .post(this.requestDomain + name, Qs.stringify(data), headers)
        .then((res) => {
          if (res.data.code == 0) {
            cb?.(res.data);
          } else if (res.data.code == 5) {
            this.store.Logout();
          } else {
            // console.log(123)
            // this.store.showToast(res.data.msg);
            ElMessage({
              message: res.data.msg,
              type: "error",
            });
          }
          return res;
        });
    }
  },

  // ===================首页============================
  // banner
  index_banner_list(data, cb) {
    return this.axiosapi("index/ad-list", "GET", data, (res) => {
      if (cb) cb(res);
    });
  },
  // 最新动态
  index_dynamic_list(data, cb) {
    this.axiosapi("index/dynamic", "GET", data, (res) => {
      if (cb) cb(res);
    });
  },
  // 热门 IP 推荐
  hot_ip_list(data, cb) {
    this.axiosapi("index/hot-ip-list", "GET", data, (res) => {
      if (cb) cb(res);
    });
  },
  // 明星艺人推荐
  star_list(data, cb) {
    this.axiosapi("index/star-list", "GET", data, (res) => {
      if (cb) cb(res);
    });
  },
  // 垂直行业专家
  expert_list(data, cb) {
    this.axiosapi("index/expert-list", "GET", data, (res) => {
      if (cb) cb(res);
    });
  },
  // 动漫游戏推荐
  comic_list(data, cb) {
    this.axiosapi("index/comic-list", "GET", data, (res) => {
      if (cb) cb(res);
    });
  },
  // 文化艺术
  culture_list(data, cb) {
    this.axiosapi("index/culture-list", "GET", data, (res) => {
      if (cb) cb(res);
    });
  },

  // 注册
  register(data, cb) {
    this.axiosapi("index/register", "POST", data, (res) => {
      if (cb) cb(res);
    });
  },
  // 登录
  login(data, cb) {
    this.axiosapi("index/login", "POST", data, (res) => {
      if (cb) cb(res);
    });
  },
  // 退出登录
  logout(data, cb) {
    this.axiosapi("index/logout", "GET", data, (res) => {
      if (cb) cb(res);
    });
  },
  //IP 权限检测
  check_ip_right(data, cb) {
    return this.axiosapi("ip/check-ip-right", "GET", data, (res) => {
      cb?.(res);
    });
  },
  // =================================通用==================
  // 发短信
  send_msg_code(data, cb) {
    this.axiosapi("index/send-sms-code", "GET", data, (res) => {
      if (cb) cb(res);
    });
  },
  // 行业字典
  industry_dict(data, cb) {
    this.axiosapi("dict/industry-dict", "GET", data, (res) => {
      if (cb) cb(res);
    });
  },
  // 添加用户行为日志
  user_behavior_log(data, cb) {
    // 未登录 则不请求行为接口
    if (!this.store.logins.loginStatus) {
      return;
    }
    this.axiosapi("log/user-behavior-log", "POST", data, (res) => {
      if (cb) cb(res);
    });
  },
  // 发送邮箱验证码
  send_email_code(data, cb) {
    this.axiosapi("index/send-email-code", "GET", data, (res) => {
      if (cb) cb(res);
    });
  },

  // 个人中心=================

  // IP收藏列表
  collection_list(data, cb) {
    this.axiosapi("user/collection-list", "GET", data, (res) => {
      if (cb) cb(res);
    });
  },

  // 保存基本信息
  save_basic_info(data, cb) {
    this.axiosapi("user/save-basic-info", "POST", data, (res) => {
      if (cb) cb(res);
    });
  },

  // 修改密码
  update_pwd(data, cb) {
    this.axiosapi("user/update-pwd", "POST", data, (res) => {
      if (cb) cb(res);
    });
  },

  // 修改手机号
  update_phone(data, cb) {
    this.axiosapi("user/update-phone", "POST", data, (res) => {
      if (cb) cb(res);
    });
  },

  // 修改邮箱
  update_email(data, cb) {
    this.axiosapi("user/update-email", "POST", data, (res) => {
      if (cb) cb(res);
    });
  },

  // 找回密码
  find_pwd(data, cb) {
    this.axiosapi("index/find-pwd", "POST", data, (res) => {
      if (cb) cb(res);
    });
  },

  // 获取基本信息
  get_basic_info(data, cb) {
    this.axiosapi("user/get-basic-info", "GET", data, (res) => {
      if (cb) cb(res);
    });
  },

  // 获取基本信息 手机号邮箱
  get_info(data, cb) {
    return this.axiosapi("user/get-info", "GET", data, (res) => {
      if (cb) cb(res);
    });
  },

  // 重置密码
  reset_pwd(data, cb) {
    this.axiosapi("index/reset-pwd", "POST", data, (res) => {
      if (cb) cb(res);
    });
  },

  // 列表===============
  // 猜你喜欢
  guess_you_like(data, cb) {
    this.axiosapi("ip/guess-you-like", "GET", data, (res) => {
      if (cb) cb(res);
    });
  },

  // IP 详细信息 参数：ipid	是	3
  ip_detail(data, cb) {
    this.axiosapi("ip/ip-detail", "GET", data, (res) => {
      if (cb) cb(res);
    });
  },

  // IP 介绍 参数：ipid	是	3
  introduce_list(data, cb) {
    this.axiosapi("ip/introduce-list", "GET", data, (res) => {
      if (cb) cb(res);
    });
  },
  // IP 介绍 参数：ipid	是	3
  dynamic_list(data, cb) {
    return this.axiosapi("ip/dynamic-list", "GET", data, (res) => {
      cb?.(res);
    });
  },
  // 联系IP 参数：ipid	是	3
  contact_notice(data, cb) {
    return this.axiosapi("ip/contact-notice", "GET", data, (res) => {
      cb?.(res);
    });
  },
  // IP筛选器列表
  // 参数：
  // search	否	国
  // area	否	0
  // type	否	0
  // tag	否	1
  // cooperationType	否	1
  // cooperationPurpose	否	1
  // materialAuditRange	否	0
  // cooperationRange	否	1
  // payType	否	1
  // priceDown	否	1
  // priceUp	否	2
  // page	否	1
  // pageSize	否	5
  ip_filter_list(data, cb) {
    this.axiosapi("ip/ip-filter-list", "GET", data, (res) => {
      if (cb) cb(res);
    });
  },

  // IP筛选项字典
  ip_filter_options_dict(data, cb) {
    this.axiosapi("ip/ip-filter-options-dict", "GET", data, (res) => {
      if (cb) cb(res);
    });
  },

  // 取消收藏 IP 参数：ipid	是	3
  cancel_collect(data, cb) {
    this.axiosapi("ip/cancel-collect", "GET", data, (res) => {
      if (cb) cb(res);
    });
  },

  // 合作案例 参数：ipid	是	3
  cooperation_list(data, cb) {
    this.axiosapi("ip/cooperation-list", "GET", data, (res) => {
      if (cb) cb(res);
    });
  },

  // 收藏 IP 参数：ipid	是	3
  collect(data, cb) {
    this.axiosapi("ip/collect", "GET", data, (res) => {
      if (cb) cb(res);
    });
  },
  // ip管理-列表
  ip_list(data, cb) {
    return this.axiosapi("user/ip-list", "GET", data, (res) => {
      if (cb) cb(res);
    });
  },
  // ip管理-列表-下架
  off_shelf(data, cb) {
    this.axiosapi("user/off-shelf", "GET", data, (res) => {
      if (cb) cb(res);
    });
  },
  // ip管理-列表-上架
  on_shelf(data, cb) {
    return this.axiosapi("user/on-shelf", "GET", data, (res) => {
      if (cb) cb(res);
    });
  },
  // ip管理-ip基础信息-回显
  ip_detail_user(data, cb) {
    return this.axiosapi("user/ip-detail", "GET", data, (res) => {
      if (cb) cb(res);
    });
  },
  // ip管理-ip基础信息-内容类型
  ip_type_dict(data, cb) {
    this.axiosapi("dict/ip-type-dict", "GET", data, (res) => {
      if (cb) cb(res);
    });
  },
  // ip管理-ip基础信息-合作内容
  coop_type_dict(data, cb) {
    this.axiosapi("dict/coop-type-dict", "GET", data, (res) => {
      if (cb) cb(res);
    });
  },
  // ip管理-ip基础信息-标签
  tag_dict(data, cb) {
    this.axiosapi("dict/tag-dict", "GET", data, (res) => {
      if (cb) cb(res);
    });
  },
  // ip管理-ip基础信息-保存
  save_ip(data, cb) {
    this.axiosapi("user/save-ip", "POST", data, (res) => {
      if (cb) cb(res);
    });
  },
  // ip管理-ip介绍内容-列表
  ip_introduction_list(data, cb) {
    return this.axiosapi("user/ip-introduction-list", "GET", data, (res) => {
      if (cb) cb(res);
    });
  },
  // ip管理-ip介绍内容-删除
  del_ip_introduction(data, cb) {
    this.axiosapi("user/del-ip-introduction", "GET", data, (res) => {
      if (cb) cb(res);
    });
  },
  // ip管理-ip介绍内容-保存
  save_ip_introduction(data, cb) {
    this.axiosapi("user/save-ip-introduction", "POST", data, (res) => {
      if (cb) cb(res);
    });
  },
  // 国家字典
  country_dict(data, cb) {
    this.axiosapi("dict/country-dict", "GET", data, (res) => {
      if (cb) cb(res);
    });
  },
  // 省市字典
  location_dict(data, cb) {
    this.axiosapi("dict/location-dict", "GET", data, (res) => {
      if (cb) cb(res);
    });
  },
  //ip管理-粉丝规模保存
  save_ip_fans(data, cb) {
    return this.axiosapi("user/save-ip-fans", "POST", data, (res) => {
      cb?.(res);
    });
  },
  //ip管理-粉丝规模数据回显
  get_ip_fans(data, cb) {
    return this.axiosapi("user/ip-fans", "GET", data, (res) => {
      cb?.(res);
    });
  },
  //ip管理-动态列表
  get_ip_dynamic(data, cb) {
    return this.axiosapi("user/ip-dynamic-list", "GET", data, (res) => {
      cb?.(res);
    });
  },
  //ip管理-动态保存
  save_ip_dynamic(data, cb) {
    return this.axiosapi("user/save-ip-dynamic", "POST", data, (res) => {
      cb?.(res);
    });
  },
  //ip管理-动态删除
  del_ip_dynamic(data, cb) {
    return this.axiosapi("user/del-ip-dynamic", "GET", data, (res) => {
      cb?.(res);
    });
  },
  //图片上传300
  upload_image300(data, cb) {
    return this.axiosapi("user/upload-image300", "post", data, (res) => {
      cb?.(res);
    });
  },
  //提交需求
  apply_demand(data, cb) {
    return this.axiosapi("index/demand", "post", data, (res) => {
      cb && cb(res);
    });
  },
  //获取需求列表
  get_demand_list(data, cb) {
    return this.axiosapi(
      "index/ip-cooperation-demand-list",
      "GET",
      data,
      (res) => {
        cb && cb(res);
      }
    );
  },
  // 需求管理、商机响应列表
  demand_list(data, cb) {
    //1：需求管理列表；2：商机响应列表
    return this.axiosapi("user/demand-list", "GET", data, (res) => {
      cb?.(res);
    });
  },
  //需求删除
  demand_del(data, cb) {
    return this.axiosapi("user/demand-del", "GET", data, (res) => {
      cb?.(res);
    });
  },
  //商机报名、不感兴趣操作
  demand_enrol(data, cb) {
    // 1：报名；2：不感兴趣
    return this.axiosapi("user/demand-enrol", "GET", data, (res) => {
      cb?.(res);
    });
  },
  //商机详情
  demand_detail(data, cb) {
    return this.axiosapi("user/demand-detail", "GET", data, (res) => {
      cb?.(res);
    });
  },
  //站内消息
  notification_list(data, cb) {
    return this.axiosapi("notification/list", "GET", data, (res) => {
      cb?.(res);
    });
  },
};

export { API };
