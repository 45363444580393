<template>
  <div class="forget ipQuotaLimit">
    <div class="regbox">
      <div class="regcont">
        <div class="tit">
          <a @click="hideCopm" class="close"
            ><img src="../assets/images/register/close.png" alt=""
          /></a>
        </div>
        <div class="form">
          <p class="p1">此内容需登录才可查看</p>
          <p class="p1">您可查看Banner中推荐的内容</p>
          <p class="p2">
            已有账号？<a class="link" @click="showLogin">立即登录</a>
            还没有账号？<a class="link" @click="showRegister">立即注册</a>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import useStore from "@/store/index.js";
export default {
  data() {
    return {};
  },
  methods: {
    showLogin() {
      this.store.setCurComp("login");
    },
    showRegister() {
      this.store.setCurComp("register");
    },
    	hideCopm(){
				this.store.setCurComp('');
			},
  },
  created() {
    this.store = useStore();
  },
};
</script>

<style lang="less" scoped>
.ipQuotaLimit .regcont {
  width: 448px;
  height: 200px;
  text-align: center;
}
.ipQuotaLimit .regcont .form {
  width: 448px;
  padding-top: 48px;
  .p1,
  .p2 {
    color: #606060;
    font-size: 14px;
    line-height: 22px;
    padding-bottom: 8px;
    margin-top: 0;
    .link {
      color: #f67f36;
    }
    img {
      margin-top: 90px;
    }
  }
}

/* .ipQuotaLimit .regcont .form p{color: rgba(4, 4, 4, 0.8);font-size: 18px;line-height: 26px;}
.ipQuotaLimit .regcont .form >img{width: 176px;height: 176px;margin-top: 24px;}	 */
</style>
