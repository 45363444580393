<template>

	
	<div class="forget ipQuotaLimit">
			<div class="regbox ">
				<div class="regcont">
					<div class="tit"> <a @click="hideCopm" class="close"><img src="../assets/images/register/close.png" alt="" ></a></div>
					<div class="form">
						<p>当前账号达到每天可查看限制</p>
						<p>如有疑问请联系平台客服</p>
            <img alt="" src="@/assets/images/index/qrcode1.jpg" />
					</div>
				</div>
			</div>
	</div>
	
	
	
</template>

<script>
	import useStore from '@/store/index.js'
	
	export default {
		data(){
			return {
			}
		},
		created(){
			this.store = useStore()
		},
		methods:{
			hideCopm(){
				this.store.setCurComp('');
			},
		},
		
		
	}
</script>

<style scoped>
.ipQuotaLimit .regcont{width: 448px;height: 340px;text-align: center;}
.ipQuotaLimit .regcont .form {width: 448px;padding-top: 48px;}
.ipQuotaLimit .regcont .form p{color: rgba(4, 4, 4, 0.8);font-size: 18px;line-height: 26px;}
.ipQuotaLimit .regcont .form >img{width: 176px;height: 176px;margin-top: 24px;}	
</style>
