<template>
	<div class="listbox">
		<div class="title clearFix">
			<h2 >文化艺术推荐</h2>
			<a to="/ip" data='{"ipTypeLevel1":"2"}'  @click="linkTo" class="more">查看更多 ></a>
		</div>
		<div class="listcont clearFix culturelist">
			<div class="left recommendBox ">
				
					<div class="picbox">
						<a to="/detail" :data=' `{"iid": "${culturelist1.id}" }` ' class="link" @click="linkTo"><img :src="culturelist1.horizontal_img"  class="pic"></a>
					</div>
				
				<div class="txtbox">
					<p class="tit">{{culturelist1.name}}</p>
					<div class="tags">
						<a v-for="item in culturelist1.tags"  to="/ip" :data=' `{"keywords":"${item}" }` ' class="tag" @click="linkTo">{{item}}</a>
					</div>
					<p class="info">{{culturelist1.desc}}</p>
					<div class="btns">
						<a  class="btn btn_add" :class="culturelist1.collected==0?'':'active'" @click="collection_handler" ><i class="i_add"></i><span class="txt">收藏</span></a>
						<a  class="btn"  @click="qrcode_show(1)"><i class="i_contact"></i><span>联系IP</span></a>
						<a  class="btn"  @click="qrcode_show(2,culturelist1.id)"><i class="i_share"></i><span>分享IP</span></a>
					</div>
				</div>
			</div>
			<div class="list">
				<div class="item" v-for="item in culturelist2">
					<a to="/detail" :data=' `{"iid":"${item.id}" }` ' class="link" @click="linkTo">
						<div class="picbox"><img :src="item.horizontal_img"  class="pic"></div>
						<p class="tit">{{item.name}}</p>
						<p class="subtit">{{item.desc}}</p>
					</a>
				
				</div>
			</div>
		</div>
	</div>
	<transition name="fadeInLeft">
		<qrcode ref="qrcode"></qrcode>
	</transition>
</template>

<script>
	import qrcode from '@/views/home/qrcode.vue'
	import useStore from '@/store/index.js';
	import tracker from '@/common/tracker.js';
	export default{
		data(){
			return {
				culturelist1:{},
				culturelist2:[],
			}
		},
		components:{
			qrcode,
		},
		methods:{
			collection_handler(){
				let id = this.culturelist1.id
				let collected = this.culturelist1.collected
				if(collected==0){ // 收藏
				  console.log(0)
				  this.API.collect({ipid:id},(res)=>{
					  if(res.code==0){
						console.log(res);
						this.culturelist1.collected =1;
					  }else if(res.code==5){
						  this.store.setCurComp('login');
					  }
				  }); 
				}else{  // 取消收藏
				  this.API.cancel_collect({ipid:id},(res)=>{
				    console.log(res);
					this.culturelist1.collected =0;
				  });
				}
				tracker({url:this.$route.path,opType:collected==0?3:4,ipId:id})
				// this.API.user_behavior_log({url:this.$route.path,opType:collected==0?3:4,ipId:id});
			},
			qrcode_show(num,id){
				this.$refs.qrcode.showQrcode(num,id);
			},
			qrcode_hide(){
				this.$refs.qrcode.hideQrcode();
			},
			linkTo(e){
				let to = e.currentTarget.getAttribute("to")
				let data = e.currentTarget.getAttribute("data")
				this.utils.goto(this,to,JSON.parse(data))
			},
		},
		created(){
			this.store = useStore()
			this.API.culture_list({limit:7},(res)=>{
				 res.data.forEach((item,index)=>{
					 if(index<1){
						 this.culturelist1 = item
					 }else{
						 this.culturelist2.push(item)
					 }
				 })
			 })
		},
	}
</script>

<style scoped>
	/* 热门ip推荐 */
	.culturelist .recommendBox{width: 534px;float: left;height: 448px;background: #FFFFFF;border-radius: 4px;border: 1px solid #DBDEE9;}
	.culturelist .recommendBox .btns{margin-top: 26px;}
	.culturelist .recommendBox .picbox{border-radius:4px 4px 0 0 ;height: 222px;;}
	.culturelist .recommendBox .picbox .link{display: inline-block;width: 100%;height: 100%;}
	.culturelist .recommendBox .picbox .pic{width: 100%;height: 100%;object-fit: cover;}
	.culturelist .recommendBox .tit{font-size: 18px;color: #222222;line-height: 25px;}
	.culturelist .recommendBox .tags{margin-top: 11px;height: 20px;overflow: hidden;;}
	.culturelist .recommendBox .tags .tag{padding: 0 10px;font-size: 12px;margin-right: 10px;color: #606060;line-height: 20px;max-width: 80%;background: #ECEFF6;border-radius: 10px;display: inline-block;}
	.culturelist .recommendBox .info{font-size: 14px;color: #727272;line-height: 22px;margin-top: 22px;overflow:hidden; text-overflow:ellipsis;display:-webkit-box; -webkit-box-orient:vertical;-webkit-line-clamp:2; }
	.culturelist .recommendBox .txtbox{padding: 18px 22px;}
	.culturelist .recommendBox .btns a{width: 80px;height: 28px;border-radius: 4px;border: 1px solid #F5630B;text-align: center;margin-right: 24px;}
	.culturelist .recommendBox .btns i{width: 16px;height: 16px;display: inline-block;vertical-align: middle;margin-right: 5px;}
	.culturelist .recommendBox .btns .i_add{background: url(../../assets/images/index/i_add.png);background-size: cover;}
	
	.culturelist .recommendBox .btns .active {background: #F5630B;color: #fff;;}
	/* .culturelist .recommendBox .btns .active .i_add{background: url(../../assets/images/index/i_add_on.png);} */
	.culturelist .recommendBox .btn_add .txt:before{content:'未';font-size: 14px;}
	.culturelist .recommendBox .btn_add.active .txt:before{content:'已';}
	.culturelist .recommendBox .btns .i_share{background: url(../../assets/images/index/i_share.png);}
	.culturelist .recommendBox .btns .i_contact{background: url(../../assets/images/index/i_contact.png);}
	.culturelist .recommendBox .btns span{display: inline-block;vertical-align: middle;line-height: 28px;}
	.culturelist .list {float: left;width:828px;}
	.culturelist .list .item:nth-child(3n){margin-right: 0;}
	.culturelist .list .item{width: 260px;margin-left: 15px;margin-bottom: 15px;float: left;}
	.culturelist .list  a{display: block;}
	.culturelist .list .item .picbox{height: 150px;width: 260px;}
	.culturelist .list .item .picbox .pic{width: 100%;height: 100%;object-fit: cover;}
	.culturelist .list .tit{font-size: 16px;color: #222222;line-height: 22px;padding-top: 10px;padding-left: 8px;overflow: hidden;text-overflow: ellipsis;white-space: nowrap;}
	.culturelist .list .subtit{font-size: 14px;color: #888888;line-height: 20px;padding-top: 8px;padding-left: 8px;overflow: hidden;text-overflow: ellipsis;white-space: nowrap;}
</style>
