<template>
	<div class="listbox">
		<div class="title clearFix">
			<h2 >垂直行业专家推荐</h2>
			<a to="/ip" data='{"ipTypeLevel1":"4"}'  @click="linkTo" class="more">查看更多 ></a>
		</div>
		<div class="listcont clearFix expertlist">
			<div class="list">
				<div class="item" v-for="item in expertlist">
					<a to="/detail" :data=' `{"iid": "${item.id}" }` ' class="link" @click="linkTo">
						<div class="picbox"><img :src="item.logo"  alt="" class="pic"></div>
					</a>
					<div  class="link">
						<p class="name"><a to="/detail" :data=' `{"iid": "${item.id}" }` ' @click="linkTo">{{item.name}}</a></p>
						<p class="tags"><a to="/ip" :data=' `{"keywords":"${item.tags}" }` ' @click="linkTo">{{item.tags}}</a></p>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default{
		data(){
			return {
				expertlist:[
					// {img:'',name:'Quinten KortumQuinten KortumQuinten KortumQuinten Kortum',tags:['颜值','娱乐']},
					// {img:'',name:'Quinten Kortum',tags:['颜值','娱乐']},
					// {img:'',name:'Quinten Kortum',tags:['颜值主播','娱乐主播','娱乐主播']},
					// {img:'',name:'Quinten Kortum',tags:['颜值','娱乐']},
					// {img:'',name:'Quinten Kortum',tags:['颜值','娱乐']},
					// {img:'',name:'Quinten Kortum',tags:['颜值','娱乐']},
					// {img:'',name:'Quinten Kortum',tags:['颜值','娱乐']},
					// {img:'',name:'Quinten Kortum',tags:['颜值','娱乐']},
					// {img:'',name:'Quinten Kortum',tags:['颜值','娱乐']},
					// {img:'',name:'Quinten Kortum',tags:['颜值','娱乐']},
					
				]
			}
		},
		created(){
			 this.API.expert_list({limit:10},(res)=>{
				res.data.forEach((item)=>{
				 	if(item.tags instanceof Array){
				 		item.tags =item.tags.join('/')
				 	}
				})
				this.expertlist = res.data
			 })
		},
		methods:{
			linkTo(e){
				let to = e.currentTarget.getAttribute("to")
				let data = e.currentTarget.getAttribute("data")
				this.utils.goto(this,to,JSON.parse(data))
			},
		}
		
	}
</script>

<style scoped>
	.expertlist .list{display: flex;flex-wrap: wrap;}
	.expertlist .list .item{margin-bottom: 16px;width: 260px;height: 196px;background: #FFFFFF;border-radius: 4px;border: 1px solid #E3E6EC;text-align: center;margin-right: 16px;;}
	.expertlist .list .item:nth-child(5n){margin-right: 0;}
	.expertlist .list .picbox{width: 88px;height: 88px;border-radius: 7px 7px 7px 0px;border: 1px solid #EDEEEF;margin:24px auto 0;}
	.expertlist .list .picbox .pic{object-fit: cover;width: 100%;height: 100%;}
	.expertlist .list a.link{display: block;}
	.expertlist .list .name{font-size: 14px;color: #222;line-height: 20px;padding-top: 10px;width: 90%;margin: 0 auto;overflow: hidden;text-overflow: ellipsis;white-space: nowrap;}
	.expertlist .list .tags{padding: 0 10px;font-size: 12px;color: #606060;line-height: 20px;max-width: 80%;margin-top: 8px;;background: #ECEFF6;border-radius: 10px;display: inline-block;}
	.expertlist .list a{color: #606060;}
	.expertlist .list .item:hover{box-shadow: 0px 16px 40px 0px rgba(0, 0, 0, 0.06);}
</style>