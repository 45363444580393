<template>
  <div
    class="listbox"
    v-show="demandList.length > 0 || isFirst"
    v-loading="loading"
  >
    <div class="title list_box_title">
      <h2>IP合作需求</h2>

      <div class="right">
        <demandApplyModal btnWidth="160px" btnHeight="40px" />
        <div class="pagination" v-show="total > 4">
          <span
            :class="{
              pagination_pre: true,
              pagination_disable: pageNumber <= 1,
            }"
            @click="changePage('sub')"
            ><el-icon><ArrowLeftBold /></el-icon
          ></span>
          <span class="pagination_page">{{ pageNumber }}/{{ pageTotal }}</span>
          <span
            :class="{
              pagination_next: true,
              pagination_disable: pageNumber >= pageTotal,
            }"
            @click="changePage('add')"
            ><el-icon><ArrowRightBold /></el-icon
          ></span>
        </div>
      </div>
    </div>

    <div class="listcont clearFix expertlist">
      <div class="list">
        <div
          class="item"
          v-for="(item, index) in demandList"
          :key="index"
          @click="linkTo(item)"
        >
          <div class="demandTitle">{{ item.brand }}</div>
          <p style="margin-top: 24px" class="budgetText">
            <span class="title">IP预算：</span>{{ item.budget_range }}
          </p>
          <p class="background">
            <span class="title">项目背景：</span>{{ item.project_bg }}
          </p>
          <p><span class="title">有效截止时间：</span>{{ item.deadline }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ArrowRightBold, ArrowLeftBold } from "@element-plus/icons-vue";
import demandApplyModal from "./demandApplyModal";
export default {
  components: {
    ArrowRightBold,
    ArrowLeftBold,
    demandApplyModal,
  },
  data() {
    return {
      demandList: [],
      pageNumber: 1,
      pageSize: 4,
      total: 2,
      isFirst: false, //只有第一次请求没有数据后才会隐藏当前模块
      loading: true,
    };
  },
  created() {
    // 获取IP合作需求List
    this.getList();
  },
  computed: {
    pageTotal() {
      return Math.ceil(this.total / this.pageSize);
    },
    params() {
      return {
        page: this.pageNumber,
        length: this.pageSize,
      };
    },
  },
  methods: {
    changePage(type) {
      if (type === "sub" && this.pageNumber > 1) {
        this.pageNumber = this.pageNumber - 1;
      } else if (type === "add" && this.pageNumber < this.pageTotal) {
        this.pageNumber = this.pageNumber + 1;
      }
      this.getList();
    },
    async getList() {
      this.loading = false;
      await this.API.get_demand_list(this.params, (res) => {
        const { code, data } = res;
        if (code === 0) {
          this.total = data.total;
          if (!this.isFirst) {
            this.isFirst = +data.total > 0;
          }
          this.demandList = data.list;
        }
      });
      this.loading = false;
    },
    linkTo(item) {
      const { id, detail_right } = item;
      if (detail_right) {
        this.utils.goto(this, "/person/niche/detail", { id });
      } else {
        this.$message({
          type: "warning",
          message: "暂无查看权限，请联系客服开通",
        });
      }
    },
  },
};
</script>

<style scoped lang="less">
.list_box_title {
  width: 100%;
  display: flex;
  height: 40px;
  justify-content: space-between;
}
.right {
  display: flex;
  height: 40px;
  align-items: center;
}

.pagination {
  margin-left: 32px;
  display: flex;
  align-items: center;
  font-family: PingFangSC-Regular, PingFang SC;
  user-select: none;
  .pagination_pre,
  .pagination_next {
    width: 40px;
    height: 40px;
    background: #ffffff;
    border-radius: 4px;
    border: 1px solid #e3e6ec;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    > i {
      font-size: 18px;
      color: rgba(19, 20, 28, 0.9);
      > svg {
        font-size: 18px;
      }
    }
  }
  .pagination_page {
    font-size: 14px;
    color: rgba(19, 20, 28, 0.9);
    margin: 0 9px;
  }
  .pagination_disable {
    cursor: no-drop;
    > i {
      color: #bbbbbd;
    }
  }
}

.expertlist .list {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
}
.expertlist .list .item {
  margin-bottom: 16px;
  //   width: 330px;
  height: 324px;
  background: #ffffff;
  border-radius: 4px;
  border: 1px solid #dbdee9;
  margin-right: 16px;
  padding: 24px;
  cursor: pointer;

  .demandTitle {
    height: 51px;
    line-height: 24px;
    font-size: 18px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #222222;
    border-bottom: 1px solid #dbdee9;
    margin-bottom: 8px;
    box-sizing: content-box;

    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-all;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  .budgetText {
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-all;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }

  p {
    margin-top: 16px;
    font-size: 14px;
    color: #888888;
    line-height: 22px;
    .title {
      color: #222222;
    }
  }
  .background {
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-all;
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
    height: 110px;
  }
}

.expertlist .list .item:nth-child(4n) {
  margin-right: 0;
}
</style>
