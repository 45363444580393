<template>

	
	<div class="forget authentication">
			<div class="regbox ">
				<div class="regcont">
					<div class="tit"> <a @click="hideCopm" class="close"><img src="../assets/images/register/close.png" alt="" ></a></div>
					<div class="form">
						<p>当前帐号信息未签署入驻合同</p>
						<p>请联系平台客服签署</p>
						<img src="@/assets/images/index/qrcode1.jpg" alt="" class="qrcode">
					</div>
				</div>
			</div>
		</div>
	
	
	
</template>

<script>
	import useStore from '@/store/index.js'
	
	export default {
		data(){
			return {
			}
		},
		created(){
			this.store = useStore();
			this.logins = this.store.logins;
		},
		computed:{
			loginCloseShow(){
				return this.store.logins.loginCloseShow;
			}
		},
		methods:{
			hideCopm(){
				this.store.setCurComp('');
			},
		},
		
		
	}
</script>

<style scoped>
.authentication .regcont{width: 410px;height:auto;text-align: center;}
.authentication .regcont .form {width: 410px}
.authentication .regcont .form p{color: #2E3036;font-size: 14px;margin-top: 15px;;}
.authentication .regcont .btn1{width: 164px;margin-top: 32px;line-height: 42px;height: 42px;;}	
.qrcode{
	width: 176px;
	height: 176px;
	margin: 24px 0 40px;

}
</style>
