<template>
	<div class="listbox">
		<div class="title clearFix">
			<h2 >热门IP推荐</h2>
			<a to="/ip" data='{}'  @click="linkTo" class="more">查看更多 ></a>
		</div>
		<div class="listcont clearFix iplist">
			<el-carousel class="left swiper" height="316px" v-show="isSwiperShow">
				<el-carousel-item v-for="item in iplist1"  interval="5000">
					<a to="/detail" :data=' `{"iid": "${item.id}" }` ' class="link" @click="linkTo">
						<img :src="item.horizontal_img"  alt="" class="pic">
						<div class="mask">
							<p class="tit1">{{item.name}}</p>
							<p class="subtit1" >{{item.desc}}</p>
						</div>
					</a>
				</el-carousel-item>
			</el-carousel>
			<div class="list">
				<div class="item" v-for="item in iplist2"  >
					<a to="/detail" :data=' `{"iid": "${item.id}" }` ' class="link" @click="linkTo">
						<div class="picbox"><img :src="item.horizontal_img"  class="pic"></div>
						<div class="mask">
							<p class="txt">{{item.name}}</p>
						</div>
					</a>
				
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default{
		
		data(){
			return {
				iplist1:[1,2],
				isSwiperShow:false,
				iplist2:[]
			}
		},
		created(){
			 this.API.hot_ip_list({limit:10},(res)=>{
				this.iplist1 = []
				this.isSwiperShow = true;
				res.data.forEach((item,index)=>{
					 if(index<4){
						 this.iplist1.push(item)
					 }else{
						 this.iplist2.push(item)
					 }
				 })
			 })
		},
		methods:{
			linkTo(e){
				let to = e.currentTarget.getAttribute("to")
				let data = e.currentTarget.getAttribute("data")
				this.utils.goto(this,to,JSON.parse(data))
			},
		}
		
		
	}
</script>

<style scoped>
	/* 热门ip推荐 */
	.iplist{position: relative;}
	.iplist .swiper{width:534px;height: 316px;float: left;}
	.iplist .swiper :deep( .el-carousel__button){width: 8px;height: 8px;background: rgba(255, 255, 255, 0.9);border-radius: 100%;}
	.iplist .swiper :deep(.el-carousel__indicators){left: auto;right: 20px;transform: translate(0,0);}
	.link{display: inline-block;height: 100%;position: relative;}
	.iplist .swiper .pic{border-radius: 4px;width: 100%;height: 100%;object-fit: cover;}
	.iplist .mask{border-radius: 4px;width: 100%;padding:  24px;object-fit: cover;position: absolute;bottom: 0;left: 0;;background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.5) 100%)}
	.iplist .swiper .tit1{color: #fff;font-size: 16px;color: #fff;;line-height: 20px;}
	.iplist .swiper .subtit1{color: #fff;font-size: 12px;color: #fff;line-height: 20px;overflow: hidden; text-overflow: ellipsis; display: -webkit-box; -webkit-box-orient: vertical; -webkit-line-clamp: 2;}
	.iplist .list {float: left;width: 828px;}
	.iplist .list .pic{border-radius: 4px;width:260px;height: 150px;object-fit: cover;}
	.iplist .list .item{width: 260px;height: 150px;margin-left: 14px;margin-bottom: 14px;float: left;}
	.iplist .list .txt{color: #fff;font-size: 16px;}
	.iplist .list .mask{padding: 10px;}
</style>
