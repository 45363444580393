<template>

	
	<div class="forget">
			<div class="regbox ">
				<div class="regcont">
					<div class="tit">忘记密码 <a v-show="loginCloseShow" @click="hideCopm" class="close"><img src="../assets/images/register/close.png" alt="" ></a></div>
					<div class="form">
						<div class="box box1 " v-show="!canModify">
							<div class="li"><input type="text" v-model="phone" class="input1" placeholder="请输入手机号" maxlength="11"></div>
							<div class="li verifyCode">
								<input type="text" class="input1" v-model="code" placeholder="请输入验证码" maxlength="6">
								<a  class="btnSend" @click="getCode" ref="btnSend">获取验证码</a>
							</div>
							<div class="li">
								<a  class="btn1" @click="findPassword">找回密码</a>
							</div>
							<div class="li p1">
								记起密码？？<a  class="link" @click="toLogin">立即登录</a>
							</div>
						</div>
						<div class="box box2 "  v-show="canModify">
							<div class="li"><input type="password" v-model="pwd" class="input1" maxlength="16"  placeholder="请输入不少于8位的密码，需包含字母数字"></div>
							<div class="li"><input type="password" v-model="pwd_confirm" class="input1" maxlength="16"  placeholder="请确认密码"></div>
							<div class="li mt46">
								<a  class="btn1" @click="modifyCopm">完成</a>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	
	
	
</template>

<script>
	import icom from '../common/com.js';
	import useStore from '@/store/index.js'
	
	export default {
		data(){
			return {
				phone:'',
				code:'',
				canModify:false,
				pwd:'',
				pwd_confirm:'',
				token:'',
				countDownTime:'获取验证码'
			}
		},
		created(){
			this.store = useStore()
		},
		computed:{
			loginCloseShow(){
				return this.store.logins.loginCloseShow;
			}
		},
		methods:{
			hideCopm(){
				this.store.setCurComp('');
			},
			getCode(){
				let isPhone = this.checkPhone();
				if(!isPhone) return
				// 1：注册；2：忘记密码 3：修改密码
				this.API.send_msg_code({phone:this.phone,source:2},(res)=>{
					icom.countdown(this.$refs.btnSend,60);
				})
				
			},
			checkPhone(){
				if(this.phone==''){
					this.store.showToast('请输入手机号')
					return false
				}
				// 未注册时候
				// 该手机号未注册
				if(!icom.checkStr(this.phone)){
					this.store.showToast('请输入正确的手机号')
					return false
				}
				return true
			},
			findPassword(){
				let isPhone = this.checkPhone();
				if(!isPhone) return
				if(this.code==''){
					this.store.showToast('请输入验证码')
					return
				}
				this.API.find_pwd({phone:this.phone,code:this.code},(res)=>{
					this.canModify = true;
					this.token = res.data.token
				})
				
				
			},
			modifyCopm(){
				if(this.pwd==''){
					this.store.showToast('请输入登录密码')
					return
				}
				if(!icom.checkStr(this.pwd,11)){
					this.store.showToast('请输入与大于8位小于16位并且包含字母数字的密码')
					return
				}
				
				if(this.pwd_confirm==''){
					this.store.showToast('请输入确认密码')
					return
				}
				if(this.pwd_confirm!=this.pwd){
					this.store.showToast('确认密码与密码不一致')
					return
				}
				// token 这里需要传入touken
				this.API.reset_pwd({pwd:this.pwd,pwd_confirm:this.pwd_confirm,token:this.token},(res)=>{
					// 提交修改接口
					this.toLogin();
				})
				
				
			},
			toLogin(){
				this.store.setCurComp('login');
			},
			
			
			
		},
		
		
	}
</script>

<style scoped>
 
	
</style>
